import { Typography } from "@mui/material";
import React from "react";

interface AlertHeaderProps {
    header?: string;
}

const AlertHeader: React.FC<AlertHeaderProps> = ({ header = "Incidents" }) => {
    return <Typography variant="subTitle">{header}</Typography>;
};

export default AlertHeader;
