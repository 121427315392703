import { AnyAction, combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth.reducer";
import systemReducer from "./system.reducer";
import locationReducer from "./location.reducer";
import newLocationReducer from "./newLocation.reducer";
import timeReducer from "./time.reducer";
import mapReducer from "./map.reducer";
import aqIncidentReducer from "./aq-incident.reducer";
import pnrIncidentReducer from "./pnr-incident.reducer";
import reportReducer from "./report.reducer";
import graphReducer from "./graph.reducer";
import deviceReducer from "./devices.reducer";
import { ActionTypes } from "./action-types/action-types";
import taxirankIncidentReducer from "./taxirank-incident.reducer";

const appReducer = combineReducers({
    auth: authReducer,
    system: systemReducer,
    location: locationReducer,
    time: timeReducer,
    map: mapReducer,
    newLocation: newLocationReducer,
    aqIncident: aqIncidentReducer,
    taxiRankIncident: taxirankIncidentReducer,
    pnrIncident: pnrIncidentReducer,
    report: reportReducer,
    graph: graphReducer,
    device: deviceReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === ActionTypes.CLEAR_STORE) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export type ApplicationState = ReturnType<typeof rootReducer>;

export default rootReducer;
