import React, { useEffect, useState } from "react";
import SectionHeading from "components/headings/SectionHeading";
import { AiFillAppstore, AiFillBank } from "react-icons/ai";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import IncidentsStatisticsCards from "features/app-taxi-ranking/statistics/IncidentsStatisticsCards";
import RegionDetailCard from "components/cards/RegionDetailCard";
import ZoneDetailCard from "components/cards/ZoneDetailCard";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { Location } from "reducers/newLocation.reducer";
import { useNavigate } from "react-router";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { findNoOfChildren } from "utils/search-tree";
import { navigateTo404Page } from "utils/navigation";
import { LocationType } from "enums/location.enums";
import { FaMapMarkerAlt } from "react-icons/fa";
import IncidentsTable from "./IncidentsTable";

const IncidentPage = (): JSX.Element => {
    const [selectedLocation, setSelectedLocation] = useState<Location | null | undefined>(null);
    const [parent, setParent] = useState<Location>();
    const { locationId, groupId } = useParams();
    const { locations } = useSelector((state) => state.newLocation);
    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);

    useEffect(() => {
        const selected = locations.find((location) => location.id === locationId || location.id === groupId);
        if ((locationId || groupId) && !selected) {
            navigateTo404Page(navigate, appCode);
        } else {
            setSelectedLocation(selected);
            const selectedParent = locations.find((location) => location.id === selected?.parentId);
            setParent(selectedParent);
        }
    }, [locationId, locations, groupId]);

    return (
        <>
            <Grid container spacing={1}>
                {selectedLocation && selectedLocation.type === LocationType.REGION && (
                    <>
                        <Grid item xs={12}>
                            <RegionDetailCard
                                region={selectedLocation.name}
                                options={[
                                    {
                                        name: "Total number of LGAs",
                                        icon: <AiFillBank />,
                                        value: findNoOfChildren(selectedLocation, locations),
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                            <SectionHeading heading="Incident Summary" icon={<AiFillAppstore className="icon" />} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                            <div
                                style={{
                                    maxWidth: "300px",
                                    marginLeft: "auto",
                                }}
                            >
                                <HeaderDatePicker />
                            </div>
                        </Grid>
                        {selectedLocation?.id === groupId && (
                            <Grid item xs={12}>
                                <IncidentsStatisticsCards group={selectedLocation} />
                            </Grid>
                        )}
                    </>
                )}
                {selectedLocation && selectedLocation.type === LocationType.ZONE && (
                    <>
                        <Grid item xs={12}>
                            <ZoneDetailCard
                                regionType={parent?.name || ""}
                                region={selectedLocation.name}
                                options={[
                                    {
                                        name: "Total number of locations",
                                        value: findNoOfChildren(selectedLocation, locations),
                                        icon: <FaMapMarkerAlt />,
                                    },
                                    // {
                                    //     name: "Total number of cameras",
                                    //     value: selectedGroup.devicesCount,
                                    //     icon: <GiCctvCamera />
                                    // }
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                            <SectionHeading heading="Incident Summary" icon={<AiFillAppstore className="icon" />} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                            <div
                                style={{
                                    maxWidth: "300px",
                                    marginLeft: "auto",
                                }}
                            >
                                <HeaderDatePicker />
                            </div>
                        </Grid>
                        {selectedLocation?.id === groupId && (
                            <Grid item xs={12}>
                                <IncidentsStatisticsCards group={selectedLocation} />
                            </Grid>
                        )}
                    </>
                )}
                {selectedLocation === undefined && (
                    <>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                            <SectionHeading
                                heading="Incidents for All Locations"
                                icon={<AiFillAppstore className="icon" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                            <div
                                style={{
                                    maxWidth: "300px",
                                    marginLeft: "auto",
                                }}
                            >
                                <HeaderDatePicker />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <IncidentsStatisticsCards />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <IncidentsTable />
                </Grid>
            </Grid>

            {locations.length === 0 && selectedLocation === undefined && (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <LoadingWrapper isLoading />
                </Box>
            )}
        </>
    );
};

export default IncidentPage;
