/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, CircularProgress, Grid, IconButton, Link, Tooltip } from "@mui/material";
import { GridColumns, GridRenderCellParams, GridRowsProp, GridSortModel } from "@mui/x-data-grid";
import { executeGetIncidentData, executeGetIncidentVideoStatusRequest } from "api/app-taxi-ranking/taxi-incidents.api";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import IncidentModal from "features/app-taxi-ranking/dialogs/IncidentModal/IncidentModal";
import TableHeading from "components/headings/TableHeading";
import PaperWrapper from "components/wrappers/PaperWrapper";
import React, { useEffect, useRef, useState } from "react";
import { CgArrowsExpandRight } from "react-icons/cg";
import { useSelector } from "react-redux";
import { useParams, useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import DateView from "components/dates/DateView";
import IotVisionTable from "components/common/IotVisionTable";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import { CommonObjectLiteral, Incident } from "types";
import { useQueryParams } from "hooks/useQueryParams";
import { enAU } from "date-fns/locale";
import { Location } from "reducers/newLocation.reducer";
import { LocationType } from "enums/location.enums";
import { camelCaseToTitleCase, checkLocationIdChanged } from "utils/common";
import { incidentFileStatusMapping } from "configs/incidents";
import { IncidentModalChangeablePropertyType } from "enums/incident.enum";
import IncidentsPageFilterPanel from "./IncidentsPageFilterPanel";

const PAGE_SIZE = 5;

interface lgaOrLocation {
    id: string;
    label: string;
}
interface OptionsLgaOrLocation {
    name: string;
    value: lgaOrLocation[];
}

export enum sortOrder {
    ASC = "asc",
    DESC = "desc",
}

interface VideoStatus {
    incidentId: string;
    status: string;
    isRequested: boolean;
}

const IncidentsTable = () => {
    const incidentFilterAbortControllerRef = useRef<AbortController | null>(null);
    const incidentVideoStatusAbortControllerRef = useRef<AbortController | null>(null);
    const viewButtonRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    let { selectedIncident, pageIndex } = useQueryParams();
    if (!pageIndex) pageIndex = 1;
    const [searchParams, setUrlParams] = useSearchParams();
    const [selectedGroup, setSelectedGroup] = useState<Location>();
    const { groupId, locationId } = useParams();
    const { locations } = useSelector((state) => state.newLocation);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [allLocationIncidentsData, setAllLocationIncidentsData] = React.useState<GridRowsProp>([]);
    const [videoStatus, setVideoStatus] = React.useState<Record<string, VideoStatus>>({});
    const [videoStatusLoading, setVideoStatusLoading] = React.useState<Record<string, boolean>>({});
    const [rowsCount, setRowsCount] = React.useState(0);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const [modalShowSnapshot, setModalShowSnapshot] = React.useState(false);
    const [incidentIds, setIncidentIds] = React.useState<string[]>([]);
    const { currentIndex } = useSelector((state) => state.taxiRankIncident);
    const [currentIncident, setCurrentIncident] = React.useState<number>(0);
    const [previousIncidentData, setPreviousIncidentData] = useState({ id: "", navDir: "" });

    const groups = locations.filter(
        (location: Location) => location.type === LocationType.REGION || location.type === LocationType.ZONE,
    );

    const commonIncidentTableColumns1: GridColumns = [
        {
            field: "date",
            headerName: "Date and Time",
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips
                    title={
                        <DateView dateFormat="Ppp" options={{ locale: enAU }}>
                            {params.row.date}
                        </DateView>
                    }
                    arrow
                >
                    <span>
                        <DateView>{params.row.date}</DateView>
                    </span>
                </IotVisionToolTips>
            ),
            flex: 1,
            minWidth: 150,
        },
        {
            field: "idInc",
            headerName: "ID",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
        },
        {
            field: "altIdentificationNo",
            headerName: "Rego",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
        },
        {
            field: "assigneeName",
            headerName: "Assignee",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.assigneeName || ""} arrow>
                    <span> {params.row.assigneeName}</span>
                </IotVisionToolTips>
            ),
        },
    ];
    const commonIncidentTableColumns2: GridColumns = [
        {
            field: "incident",
            headerName: "Incident Type",
            flex: 1.5,
            minWidth: 100,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <Tooltip title={params.row.incident || ""} arrow>
                    <span className="table-cell-trucate">{params.row.incident}</span>
                </Tooltip>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.status?.name} arrow>
                    <span>{params.row.status?.name}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "videoStatus",
            headerName: "Video Status",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }: GridRenderCellParams) => {
                const incidentVideo = videoStatus[row.id];
                if (videoStatusLoading[row.id])
                    return (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={locationId ? "65%" : (selectedGroup?.type === LocationType.ZONE && "85%") || "100%"}
                        >
                            <CircularProgress sx={{ color: "#6b439d" }} size={18} />
                        </Box>
                    );
                if (!incidentVideo?.isRequested) return <></>;
                const status =
                    incidentFileStatusMapping[incidentVideo?.status || ""]?.displayValue ||
                    camelCaseToTitleCase(incidentVideo?.status || "");
                return (
                    <IotVisionToolTips
                        title={status}
                        arrow
                    >
                        <span
                            style={{
                                color: incidentFileStatusMapping[incidentVideo?.status]?.color,
                                textShadow: `0px 0px 0px ${incidentFileStatusMapping[incidentVideo?.status]?.color}`,
                            }}
                        >
                            {status}
                        </span>
                    </IotVisionToolTips>
                );
            },
        },
        {
            field: "snapshot",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            align: "right",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Link
                        component="button"
                        style={{ color: "#6b439d", fontSize: "14px" }}
                        ref={viewButtonRef}
                        onClick={() => {
                            setModalShowSnapshot(true);
                            const queryParams = Object.fromEntries([...searchParams]);
                            setUrlParams({
                                ...queryParams,
                                selectedIncident: params.row.id,
                            });
                            setCurrentIncident(incidentIds.indexOf(params.row.id));
                        }}
                    >
                        View
                    </Link>
                );
            },
            flex: 1,
        },
    ];

    const lgaColumn: GridColumns = [
        {
            field: "lga",
            headerName: "LGA",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params?.row?.lga} arrow>
                    <span>{params?.row?.lga}</span>
                </IotVisionToolTips>
            ),
        },
    ];

    const locationTableColumn: GridColumns = [
        {
            field: "location",
            headerName: "Location",
            flex: 1.5,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.location || ""} arrow>
                    <span> {params.row.location}</span>
                </IotVisionToolTips>
            ),
        },
    ];

    const columns =
        locationId !== undefined
            ? commonIncidentTableColumns1.concat(commonIncidentTableColumns2)
            : selectedGroup && selectedGroup?.type === LocationType.ZONE
            ? commonIncidentTableColumns1.concat(locationTableColumn, commonIncidentTableColumns2)
            : commonIncidentTableColumns1.concat(lgaColumn, locationTableColumn, commonIncidentTableColumns2);

    const isAllLocation = groupId === undefined ? locationId === undefined : false;
    const isRegion = !!(selectedGroup && selectedGroup.type === LocationType.REGION);

    const isLga = !!(selectedGroup && locationId === undefined && selectedGroup.type === LocationType.ZONE);

    const {
        altIdentificationNo,
        fromTime,
        toTime,
        type,
        status,
        incident,
        idInc,
        assignedUserID,
        preFilter,
        pageIndex: page,
    } = Object.fromEntries([...searchParams]) || {};

    const searchLocationId = searchParams?.get("locationId");
    const searchGroupId = searchParams?.get("groupId");

    const handleSortModelChange = (newModel: GridSortModel) => {
        setUrlParams({
            ...Object.fromEntries([...searchParams]),
            pageIndex: "1",
        });
        setSortModel(newModel);
    };

    const getAllOrSpecificRegionLgaOptions = () => {
        let value: lgaOrLocation[] = [];
        if (groupId === undefined) {
            value = groups
                .filter((group: Location) => group.type === LocationType.ZONE)
                .map((group: Location) => {
                    return { label: group.name, id: group.id };
                });
        } else {
            const valTemp =
                selectedGroup &&
                groups
                    .filter(
                        (group: Location) => group.type === LocationType.ZONE && group.parentId === selectedGroup.id,
                    )
                    .map((group: Location) => {
                        return { label: group.name, id: group.id };
                    });
            if (valTemp) value = valTemp;
        }
        return value;
    };

    const getAllOrSpecificRegionLocationOptions = () => {
        let value: lgaOrLocation[] = [];
        if (groupId === undefined) {
            value = locations
                .filter((loc: Location) => loc.type === LocationType.LOCATION)
                .map((loc: Location) => {
                    return { label: loc.name, id: loc.id };
                });
        } else if (selectedGroup && selectedGroup.type === LocationType.ZONE) {
            const selectedLocations = locations.filter((loc) => loc.parentId === selectedGroup.id);
            value =
                selectedLocations &&
                selectedLocations.map((loc: Location) => {
                    return { label: loc.name, id: loc.id };
                });
        } else if (selectedGroup && selectedGroup.type === LocationType.REGION) {
            const selectedGroups = groups.filter((group) => group.parentId === selectedGroup.id);
            selectedGroups.forEach((group) => {
                const selectedLocations = locations.filter((loc) => loc.parentId === group.id);
                selectedLocations.forEach((element) => {
                    value.push({ label: element.name, id: element.id });
                });
            });
        }

        return value;
    };

    const allRegionLgaOptions: OptionsLgaOrLocation = {
        name: "LGA",
        value: getAllOrSpecificRegionLgaOptions(),
    };

    const allLocationOptions: OptionsLgaOrLocation = {
        name: "Location",
        value: getAllOrSpecificRegionLocationOptions(),
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getIncidentDataFromApi = async (searchParams: any, sortModel: any) => {
        try {
            incidentFilterAbortControllerRef.current = new AbortController();
            const { signal } = incidentFilterAbortControllerRef.current;
            let parameters = Object.fromEntries([...searchParams]);
            if (Object.keys(Object.fromEntries([...searchParams])).length === 0) {
                parameters = { ...parameters, pageIndex: "1" };
            }
            setLoading(true);
            const response = await executeGetIncidentData(
                {
                    ...parameters,
                    pageSize: PAGE_SIZE?.toString(),
                    groupId: searchParams.get("groupId") ? searchParams.get("groupId") : groupId,
                    locationId: searchParams.get("locationId") ? searchParams.get("locationId") : locationId,
                    assignedUserID: searchParams.get("assignedUserID"),
                    ...(sortModel.length > 0
                        ? {
                              sortColum: sortModel[0]?.field,
                              sortOrder: sortModel[0]?.sort,
                          }
                        : {}),
                },
                { signal, disableNotification: checkLocationIdChanged(searchParams.get("locationId"), locations) },
            );
            if ((response.incident && response.total) !== (null || undefined) && Array.isArray(response.incident)) {
                const { incident, total } = response;
                processData(incident);
                setRowsCount(total);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    async function getIncidentsVideoStatus(incidentIds: string[]) {
        const statusList: Record<string, VideoStatus> = {};
        incidentVideoStatusAbortControllerRef.current = new AbortController();
        const { signal } = incidentVideoStatusAbortControllerRef.current;
        const promises = incidentIds.map(async (incidentId: string) => {
            try {
                const response = await executeGetIncidentVideoStatusRequest(
                    {
                        referenceDomain: "Incident",
                        referenceId: incidentId,
                    },
                    { signal },
                    );
                    if(response?.length) {
                        const [{ status }] = response;
                        statusList[incidentId] = {
                            incidentId,
                            status,
                            isRequested: true,
                        };
                    }
                    else {
                        statusList[incidentId] = {
                            incidentId,
                            status: "",
                            isRequested: false,
                        };
                    }
            }
            catch(error) {
                console.error(error);
            }
        });
        await Promise.all(promises);
        return statusList;
    }

    const updateIncidentVideoStatus = (statusObject: Record<string, VideoStatus>, isUpdating = false) => {
        if (isUpdating) {
            const key: string = Object.keys(statusObject)[0];
            setVideoStatus((prev: Record<string, VideoStatus>) => {
                const updatedIncident = statusObject[key];
                const filteredIncident = prev[updatedIncident?.incidentId];
                if (filteredIncident) return {...prev, [filteredIncident.incidentId]: updatedIncident};
                return prev;
            });
            setVideoStatusLoading(generateVideoStatusLoadingObjectList([key], false, key));
        } else {
            const keyList: string[] = Object.keys(statusObject);
            setVideoStatus(statusObject);
            setVideoStatusLoading(generateVideoStatusLoadingObjectList(keyList, false));
        }
    }

    const handleStatusChange = async (
        changedStatus: IncidentModalChangeablePropertyType,
        additionalProperties?: CommonObjectLiteral,
    ) => {
        const { incidentId } = additionalProperties || {};
        switch (changedStatus) {
            case IncidentModalChangeablePropertyType.REQUEST_VIDEO:
                if (incidentId) {
                    setVideoStatusLoading(generateVideoStatusLoadingObjectList([incidentId], true, incidentId));
                    const data = await getIncidentsVideoStatus([incidentId]);
                    updateIncidentVideoStatus(data, true);
                }
                break;
            default:
                getIncidentDataFromApi(searchParams, sortModel);
        }
    };

    const generateVideoStatusLoadingObjectList = (
        incidentIdList: string[],
        loadingStatus: boolean,
        updatingIncident?: string,
    ) => {
        if (updatingIncident) {
            const videoStatusLoadingCopy = { ...videoStatusLoading };
            videoStatusLoadingCopy[updatingIncident] = loadingStatus;
            return videoStatusLoadingCopy;
        }
        return Object.assign({}, ...incidentIdList.map((id: string) => ({ [id]: loadingStatus })));
    };

    const processData = (incidents: Incident[]) => {
        const incidentsDataMapped = incidents.map((incident: Incident) => {
            const location = locations.find((loc) => loc.id === incident.locationId);
            const lga = groups.find((group) => group.id === location?.parentId);
            return {
                ...incident,
                assigneeName: incident?.assignee?.name ? incident?.assignee?.name : "",
                location: location?.name,
                lga: lga?.name,
                altIdentificationNo: incident?.additionalAttributes?.regoUpdate
                    ? incident?.additionalAttributes?.regoUpdate
                    : incident?.altIdentificationNo,
            };
        });
        const idArray = incidentsDataMapped.map((i) => i.id);
        setIncidentIds(idArray);
        if (selectedIncident && !Object.is(selectedIncident, "undefined")) {
            const queryParams = Object.fromEntries([...searchParams]);
            if (!idArray[currentIndex ?? currentIncident]) {
                if (idArray.length) {
                    setUrlParams({
                        ...queryParams,
                        selectedIncident: idArray[idArray.length - 1],
                    });
                } else if (+queryParams.pageIndex === 1) {
                    delete queryParams?.selectedIncident;
                    selectedIncident = null;
                    searchParams.delete("selectedIncident");
                    setUrlParams({ ...queryParams });
                } else {
                    setUrlParams({
                        ...queryParams,
                        pageIndex: String(+queryParams.pageIndex - 1),
                    });
                }
            }
        }
        setAllLocationIncidentsData(incidentsDataMapped);
    };

    useEffect(() => {
        const queryParams = Object.fromEntries([...searchParams]);
        if (selectedIncident && !Object.is(selectedIncident, "undefined")) {
            if (previousIncidentData.id === incidentIds[currentIncident]) {
                if (previousIncidentData.navDir === "previous") {
                    const id = currentIncident !== 0 ? currentIncident - 1 : currentIncident;
                    setUrlParams({
                        ...queryParams,
                        selectedIncident: incidentIds[id],
                    });
                    setCurrentIncident(id);
                    setPreviousIncidentData({ id: "", navDir: "" });
                    return;
                }
                if (previousIncidentData.navDir === "next") {
                    const id = currentIncident !== PAGE_SIZE - 1 ? currentIncident + 1 : currentIncident;
                    setUrlParams({
                        ...queryParams,
                        selectedIncident: incidentIds[id],
                    });
                    setCurrentIncident(id);
                    setPreviousIncidentData({ id: "", navDir: "" });
                    return;
                }
            }
            const thisIndex = incidentIds.indexOf(selectedIncident);
            if (thisIndex !== -1) {
                if (previousIncidentData.navDir === "previous") {
                    const id = thisIndex !== 0 ? thisIndex - 1 : thisIndex;
                    setUrlParams({
                        ...queryParams,
                        selectedIncident: incidentIds[id],
                    });
                    setCurrentIncident(id);
                    setPreviousIncidentData({ id: "", navDir: "" });
                    return;
                }
                if (previousIncidentData.navDir === "next") {
                    const id = thisIndex !== PAGE_SIZE - 1 ? thisIndex + 1 : thisIndex;
                    setUrlParams({
                        ...queryParams,
                        selectedIncident: incidentIds[id],
                    });
                    setCurrentIncident(id);
                    setPreviousIncidentData({ id: "", navDir: "" });
                    return;
                }
                setUrlParams({
                    ...queryParams,
                    selectedIncident: incidentIds[thisIndex],
                });
                setCurrentIncident(thisIndex);
                return;
            }
            const id = currentIndex !== 0 ? currentIndex : currentIncident;
            if (incidentIds[id]) {
                setUrlParams({
                    ...queryParams,
                    selectedIncident: incidentIds[id],
                });
                setCurrentIncident(id);
            } else if (incidentIds.length) {
                setUrlParams({
                    ...queryParams,
                    selectedIncident: incidentIds[incidentIds.length - 1],
                });
                setCurrentIncident(incidentIds.length - 1);
            } else if (+queryParams.pageIndex !== 1) {
                setUrlParams({
                    ...queryParams,
                    pageIndex: String(+queryParams.pageIndex - 1),
                });
                setCurrentIncident(PAGE_SIZE - 1);
            } else {
                delete queryParams?.selectedIncident;
                selectedIncident = null;
                searchParams.delete("selectedIncident");
                setUrlParams({
                    ...queryParams,
                });
            }
        }
    }, [incidentIds]);

    const handleClick = (status: boolean) => {
        setModalShow(status);
    };

    const onIncidentTablePageChange = (newPage: number) => {
        if (allLocationIncidentsData.length === 0 && +pageIndex !== 0) {
            return;
        }
        navigate({
            pathname: window.location.pathname,
            search: `?${createSearchParams({
                ...Object.fromEntries([...searchParams]),
                pageIndex: (newPage + 1).toString(),
            })}`,
        });
    };

    useEffect(() => {
        getIncidentDataFromApi(searchParams, sortModel);
        return () => {
            incidentFilterAbortControllerRef.current?.abort();
            incidentVideoStatusAbortControllerRef.current?.abort();
            setVideoStatus({});
            setVideoStatusLoading({});
        };
    }, [
        altIdentificationNo,
        fromTime,
        toTime,
        type,
        status,
        incident,
        idInc,
        groupId,
        locationId,
        assignedUserID,
        page,
        sortModel,
        searchLocationId,
        searchGroupId,
        preFilter,
    ]);

    useEffect(() => {
        if (selectedIncident && !Object.is(selectedIncident, "undefined")) {
            setModalShowSnapshot(true);
        }
    }, [selectedIncident]);

    useEffect(() => {
        const selected = groups.find((group) => group.id === groupId);
        setSelectedGroup(selected);
    }, [groupId]);

    useEffect(() => {
        if (allLocationIncidentsData.length) {
            const incidentIdList = allLocationIncidentsData.map((inc) => inc.id);
            setVideoStatusLoading(generateVideoStatusLoadingObjectList(incidentIdList, true));
            (async () => {
                const data = await getIncidentsVideoStatus(incidentIdList);
                updateIncidentVideoStatus(data);
            })();
        }
    }, [allLocationIncidentsData]);

    return (
        <>
            <PaperWrapper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                                <TableHeading heading="Details of Incidents" />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                    <CgArrowsExpandRight />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <IncidentsPageFilterPanel
                            lgaOptions={allRegionLgaOptions}
                            locationOptions={allLocationOptions}
                            isAllLocationEnable={isAllLocation}
                            isRegionEnable={isRegion}
                            isLgaEnable={isLga}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <IotVisionTable
                            loading={loading}
                            sortingMode="server"
                            sortingOrder={[sortOrder.ASC, sortOrder.DESC]}
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                            columns={columns}
                            rows={allLocationIncidentsData}
                            pageSize={PAGE_SIZE}
                            page={pageIndex ? +pageIndex - 1 : 1}
                            rowCount={rowsCount}
                            onPageChange={(newPage) => {
                                onIncidentTablePageChange(newPage);
                            }}
                            rowsPerPageOptions={[PAGE_SIZE]}
                            hideFooterPagination={false}
                        />
                    </Grid>
                </Grid>
            </PaperWrapper>

            <IncidentModal
                onClose={() => {
                    const queryParams = Object.fromEntries([...searchParams]);
                    delete queryParams?.selectedIncident;
                    selectedIncident = null;
                    searchParams.delete("selectedIncident");
                    navigate({
                        pathname: window.location.pathname,
                        search: `?${createSearchParams({
                            ...queryParams,
                        })}`,
                    });
                    setModalShowSnapshot(false);
                }}
                onBackwardNavigation={() => {
                    viewButtonRef.current?.blur();
                    setModalShowSnapshot(false);
                }}
                onPropertyChange={(
                    propertyType: IncidentModalChangeablePropertyType,
                    additionalProperties?: CommonObjectLiteral,
                ) => {
                    handleStatusChange(propertyType, additionalProperties);
                }}
                modalShow={modalShowSnapshot}
            />

            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableHeading heading="Details of Incidents" />
                        </Grid>
                        <Grid item xs={12}>
                            <IncidentsPageFilterPanel
                                lgaOptions={allRegionLgaOptions}
                                locationOptions={allLocationOptions}
                                isAllLocationEnable={isAllLocation}
                                isRegionEnable={isRegion}
                                isLgaEnable={isLga}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <IotVisionTable
                                loading={loading}
                                sortingMode="server"
                                sortingOrder={[sortOrder.ASC, sortOrder.DESC]}
                                sortModel={sortModel}
                                onSortModelChange={handleSortModelChange}
                                columns={columns}
                                rows={allLocationIncidentsData}
                                pageSize={PAGE_SIZE}
                                page={pageIndex ? +pageIndex - 1 : 1}
                                rowCount={rowsCount}
                                onPageChange={(newPage) => {
                                    onIncidentTablePageChange(newPage);
                                }}
                                rowsPerPageOptions={[PAGE_SIZE]}
                                hideFooterPagination={false}
                            />
                        </Grid>
                    </Grid>
                }
            />
        </>
    );
};

export default IncidentsTable;
