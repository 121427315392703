import React from "react";
import { BiCalendarStar } from "react-icons/bi";

export interface EventItemProps {
    primaryText?: string;
    secondaryText?: string;
}
const EventItem = ({
    primaryText = "Event Alert: T9015",
    secondaryText = "Busy time - Many cyclists",
}: EventItemProps) => {
    return (
        <div className="event-item-wrapper">
            <div className="icon">
                <BiCalendarStar className="event-icon" />
            </div>
            <div className="event-content">
                <p>{primaryText}</p>
                <p>{secondaryText}</p>
            </div>
        </div>
    );
};

export default EventItem;
