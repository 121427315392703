import { Grid } from "@mui/material";
import { AppOversizedVehicles } from "constants/app.constants";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import AqIcon from "assets/images/platformity-logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Location } from "reducers/newLocation.reducer";
import { executeGetIncidentsByLocationsRequest } from "api/app-oversized-vehicles/oversized-location-layout.api";
import { navigateTo404Page } from "utils/navigation";
import { FaMapMarkerAlt } from "react-icons/fa";
import LocationInfoCard from "../../../components/cards/LocationInfoCard";

interface LocationProps {}

const LocationPage: React.FC<LocationProps> = () => {
    const { locationId } = useParams();
    const { locations } = useSelector((state) => state.newLocation);
    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);
    const [selectedLocation, setSelectedLocation] = useState<Location>();

    const incidentCountAbortControllerRef = useRef<AbortController | null>(null);
    const [incidentCount, setIncidentCount] = useState<number>(0);

    useEffect(() => {
        const selected = locations.find((location) => location.id === locationId);
        if (locationId && !selected) {
            navigateTo404Page(navigate, appCode, false);
        }
        setSelectedLocation(selected);
    }, [locationId]);

    useEffect(() => {
        if (selectedLocation) {
            getIncidentsCount({ locationId: selectedLocation?.id });
        }
        return () => {
            incidentCountAbortControllerRef.current?.abort();
        };
    }, [selectedLocation]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function getIncidentsCount(data?: any) {
        try {
            incidentCountAbortControllerRef.current = new AbortController();
            const { signal } = incidentCountAbortControllerRef.current;

            const response = await executeGetIncidentsByLocationsRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                const result = response?.find((loc) => loc.locationId === selectedLocation?.id);
                setIncidentCount(result?.count ?? 0);
            }
        } catch (e) {}
    }

    return (
        <>
            <Helmet>
                <title>{AppOversizedVehicles} | Locations</title>
                <link rel="icon" id="aq-incident" type="image/png" href={AqIcon} sizes="32x32" />
            </Helmet>
            <Grid container>
                <Grid item xs={12}>
                    <LocationInfoCard
                        name={selectedLocation?.name ?? ""}
                        location={selectedLocation?.metadata.description ?? ""}
                        longitude={parseFloat(selectedLocation?.latitude || "0").toFixed(5)}
                        latitude={parseFloat(selectedLocation?.longitude || "0").toFixed(5)}
                        scheduledRoadWorkFrom={selectedLocation?.metadata.scheduled_roadwork[0].from}
                        scheduledRoadWorkTo={selectedLocation?.metadata.scheduled_roadwork[0].to}
                        incidentsCount={incidentCount}
                        scheduleLabel="Scheduled Road Work"
                        headerIcon={<FaMapMarkerAlt />}
                        countLabel="# of Detections"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default LocationPage;
