import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    currentIndex: 0,
};

const slice = createSlice({
    name: "taxiRankIncident",
    initialState,
    reducers: {
        setCurrentId(state, action: PayloadAction<number>) {
            state.currentIndex = action.payload ?? initialState.currentIndex;
        },
    },
});

export const { setCurrentId } = slice.actions;
export default slice.reducer;
