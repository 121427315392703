import React from "react";
import { Chart, Doughnut } from "react-chartjs-2";
import { DoughnutConfig } from "types";

interface DoughnutChartProps {
    labels: string[];
    data: number[];
    config: DoughnutConfig;
    count: number | null;
    useLegend?: boolean;
}

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
/* eslint @typescript-eslint/ban-ts-comment: ["off"] */
const innerText = {
    id: "innerText",
    beforeInit(chart: any, _options: any) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
            // Call original function and bind scope in order to use `this` correctly inside it
            originalFit.bind(chart.legend)();
            // this.height += 35;
        };
    },
    beforeDraw(chart: any, args: any, options: any) {
        const {
            ctx,
            chartArea: { top, right, bottom, left, width, height },
        } = chart;
        ctx.save();
        // 1st How to get Position of label
        const yCenter = top + height / 2 + 8;
        const xCenter = width / 2;
        // 2nd How to set Styling of label
        ctx.font = `bold ${options.size ? options.size : "18px"} sans-serif`;
        ctx.textAlign = "center";
        ctx.fillStyle = options.fontColor;
        const text = options.text ? options.text : "";
        ctx.fillText(text, xCenter, yCenter);
    },
};

Chart.register(innerText);

const DoughnutChart: React.FC<DoughnutChartProps> = ({ labels, data, config, count, useLegend = false }) => {
    return (
        <Doughnut
            data={{
                labels,
                datasets: [
                    {
                        ...(config.label ? { label: config?.label } : {}),
                        data,
                        backgroundColor: config?.backgroundColor,
                        hoverOffset: config?.hoverOffset,
                    },
                ],
            }}
            options={{
                maintainAspectRatio: config?.maintainAspectRatio,
                aspectRatio: config?.aspectRatio,
                plugins: {
                    legend: {
                        display: useLegend,
                    },
                    // @ts-ignore
                    innerText: {
                        text: count,
                        size: config.middleFontSize,
                    },
                },
            }}
        />
    );
};

export default DoughnutChart;
