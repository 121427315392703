import { createSlice } from "@reduxjs/toolkit";
import { ISidebarReport } from "types/reports/interfaces/report";
import { IStructuredReportData } from "types/reports/interfaces/side-menu";
import { orderReportsByType } from "utils/data-manipulation";

interface ReportState {
    fetchedReports: ISidebarReport[];
    sideMenuData: IStructuredReportData[];
    isReportLoading: boolean;
    pageSize: number;
    isReportsLoading: boolean;
}

const initialState: ReportState = {
    fetchedReports: [],
    sideMenuData: [],
    isReportLoading: true,
    pageSize: 10,
    isReportsLoading: true,
};

const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        setReports(state, action) {
            state.fetchedReports=action.payload.data;
        },
        setReportSideMenu(state, action) {
            const unfilteredReports=action.payload.data;
            const orderedReports = orderReportsByType(unfilteredReports);
            state.sideMenuData = orderedReports;
        },
        clearReports(state) {
            state.fetchedReports = [];
        },
        searchReportsBy(state, action) {
            const searchTerm = action.payload;
            const filteredReports = state.fetchedReports.filter((report) => {
                const endingDate = new Date(report.reportTo);
                return endingDate
                    .toLocaleString("en-AU", { day: "2-digit", month: "long", year: "numeric" })
                    .replace(",", "")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            const orderedReports = orderReportsByType(filteredReports);
            state.sideMenuData = orderedReports;
        },
        toggleReportLoading(state, action) {
            state.isReportLoading = action.payload;
        },
        toggleReportsLoading(state, action) {
            state.isReportsLoading = action.payload;
        },
    },
});

export const {
    setReports,
    clearReports,
    setReportSideMenu,
    searchReportsBy,
    toggleReportLoading,
    toggleReportsLoading,
} = reportSlice.actions;
export default reportSlice.reducer;
