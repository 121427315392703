/* eslint-disable no-unused-expressions */
import {
    Grid,
    IconButton,
    PopperProps,
    useMediaQuery,
    Popper,
    Box,
    Avatar,
    InputAdornment,
    CircularProgress,
    styled,
    useTheme,
    Dialog,
    DialogContent,
    MenuItem,
    SelectChangeEvent,
    Typography,
    Divider,
    Link,
    Button,
} from "@mui/material";

import { executeGetSnapshotRequest } from "api/common/detections.api";
import {
    executeCreateIncidentCommentRequest,
    executeGetCommentsByIncidentIdRequest,
    executeGetIncidentByIdRequest,
    executeGetIncidentVideoFootageRequest,
    executeUpdateIncidentRegoRequest,
    executeUpdateIncidentStatusRequest,
    executeGetUsersRequest,
    executeAssignUserToIncidentRequest,
    executeGetIncidentVideoStatusRequest,
    executeQueueIncidentVideoRequest,
    executeObtainFileDownloadPresignedURL,
} from "api/app-taxi-ranking/taxi-incidents.api";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import { IIncidentVideoItem, IncidentFileStatus } from "types/incident/file-status";
import DateView from "components/dates/DateView";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { useQueryParams } from "hooks/useQueryParams";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Incident, Status, Comment, Assignee, IncidentStatus, CommonObjectLiteral, IDevice } from "types";
import { Location } from "reducers/newLocation.reducer";
import { ProcessedIncident } from "types/incident/processed-incident";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";
import { GrClose } from "react-icons/gr";
import { enAU } from "date-fns/locale";
import NotFound from "components/error/NotFound";
import { setNotifier } from "context/notification";
import { useSearchParams } from "react-router-dom";
import { SCOPES } from "enums/scopes.enum";
import useScopedDataFilter from "hooks/useScopedDataFilter";
import useHasScope from "hooks/useHasScope";
import { filterUsers, moveItemToFirst } from "utils/data-manipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { saveFile } from "utils/file";
import { RETENTION_PERIOD_IN_DAYS } from "constants/time.constants";
import { IncidentModalChangeablePropertyType } from "enums/incident.enum";
import VideoPlayer from "components/players/video-player";
import { executeGetAllDevicesRequest } from "api/app-taxi-ranking/taxi-video-requests.api";
import Comments from "../../comments/Comments";
import IncidentModalHeader from "./IncidentModalHeader";
import IncidentModalStatusSelect from "./IncidentModalStatusSelect";
import IncidentImage from "./IncidentImage";
import IncidentModalTextField from "./IncidentModalTextField";
import NoImage from "./NoImage";

export interface IIncidentVideoStatusParams {
    referenceDomain: string;
    referenceId: string;
}

interface ViewSnapshotDialogProps {
    modalShow: boolean;
    onClose: () => void;
    onPropertyChange?: (
        propertyType: IncidentModalChangeablePropertyType,
        additionalProperties?: CommonObjectLiteral,
    ) => void;
    onBackwardNavigation: () => void;
}
export interface CommentObj {
    comment: string;
    user?: string;
    date: string;
    picture?: string;
}

interface statusOption {
    id: string;
    name: string;
    disabled?: boolean;
}

const StyledAutocompletePopper = styled((popperProps: PopperProps) => {
    const theme = useTheme();
    const isbelowLgScreen = useMediaQuery(theme.breakpoints.down("lg"));
    return isbelowLgScreen ? (
        <Popper
            {...popperProps}
            style={{
                width: "fit-content",
            }}
            placement="bottom"
        />
    ) : (
        <Popper
            {...popperProps}
            style={{
                width: "fit-content",
            }}
            placement="bottom-start"
        />
    );
})(({ theme }) => {
    const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
    return {
        "& .MuiAutocomplete-listbox": {
            height: "100%",
            maxHeight: isXlScreen ? "600px" : "200px",
        },
    };
});

const CustomizedAssignButton = styled(Button)(() => ({
    fontWeight: 500,
    textShadow: "0.3px 0 0 #6b439d",
    color: "#6b439d",
    fontSize: "14px",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline solid 0.1em",
    },
    margin: "0px !important",
    padding: "0px !important",
    whiteSpace: "nowrap",
    wordSpacing: "1px",
    cursor: "pointer",
}));

const AssignButtonWrapper = styled(Grid)(() => ({
    position: "absolute",
    paddingTop: "3px",
}));

const IncidentModal = ({ onClose, onPropertyChange, modalShow, onBackwardNavigation }: ViewSnapshotDialogProps) => {
    const { selectedIncident } = useQueryParams();
    const [src, setSrc] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [videoLinkLoading, setVideoLinkLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [incidentData, setIncidentsData] = useState<ProcessedIncident | null>();
    const [incidentObj, setIncident] = useState<Incident | null>();
    const [assigneeList, setAssigneeList] = useState<Assignee[]>([]);
    const [assigneeObject, setAssigneeObject] = useState<Assignee | null>();
    const [statuses, setStatuses] = useState<statusOption[]>([]);
    const [comments, setComments] = useState<CommentObj[]>([]);
    const [regoUpdate, setRegoUpdate] = useState<string | undefined>("");
    const [regoEditable, setRegoEditable] = useState<boolean>(false);
    const [salesForceId, setSalesForceId] = useState<string | undefined>("");
    const [statusId, setStatusId] = useState<string>("");
    const [salesForceIdEditable, setSalesForceIdEditable] = useState<boolean>(false);
    const { locations } = useSelector((state) => state.newLocation);
    const [open, setOpen] = useState(false);
    const incidentAbortControllerRef = useRef<AbortController | null>(null);
    const snapshotAbortControllerRef = useRef<AbortController | null>(null);
    const incidentVideoDetailsAbortControllerRef = useRef<AbortController | null>(null);
    const videoPresignedURLAbortControllerRef = useRef<AbortController | null>(null);
    const videoGpuDeviceAbortControllerRef = useRef<AbortController | null>(null);
    const [loadingAssignee, setLoadingAssignee] = React.useState(false);
    const [loadingRego, setLoadingRego] = React.useState(false);
    const [loadingSalesForceId, setLoadingSalesForceId] = React.useState(false);
    const [loadingStatus, setLoadingStatus] = React.useState(false);
    const [isRegoEdited, setIsRegoEdited] = useState<boolean>(false);
    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.only("lg"));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const [isIncidentError, setIsIncidentError] = useState<boolean>(false);
    const [searchParams, setUrlParams] = useSearchParams();
    const [incidentFileData, setIncidentFileData] = useState<IIncidentVideoItem>();
    const [incidentFileStatus, setIncidentFileStatus] = useState<IncidentFileStatus>(IncidentFileStatus.LOADING);
    const [filePresignedURL, setFilePresignedURL] = useState<string>("");
    const [loadingVideoPlay, setLoadingVideoPlay] = useState<boolean>(false);
    const [loadingVideoDownload, setLoadingVideoDownload] = useState<boolean>(false);
    const [videoRequestLoading, setVideoRequestLoading] = useState<boolean>(false);
    const [isFetchingFileStatus, setIsFetchingFileStatus] = useState<boolean>(false);
    const [isPlayerOpen, setIsPlayerOpen] = useState<boolean>(false);
    const [incidentGpuDevice, setIncidentGpuDevice] = useState<IDevice | null>(null);
    const authorizedStatuses = useScopedDataFilter(SCOPES.UPDATE_INCIDENTS_STATUS, statuses);
    const hasReadIncidentFilesScope = useHasScope(SCOPES.READ_FILES_INCIDENTS);
    const { user } = useAuth0();

    useEffect(() => {
        if (modalShow) {
            setOpen(true);
        }
    });

    useEffect(() => {
        if (!selectedIncident) {
            onCloseDialog();
            onBackwardNavigation();
        } else {
            setIsIncidentError(false);
        }
    }, [selectedIncident]);

    // tempory fix till get user is done
    useEffect(() => {
        if (incidentData) {
            processComments(incidentData.comments);
        }
    }, [assigneeList]);

    useEffect(() => {
        setIsIncidentError(false);
        getUsers();
        getIncidentById();
        hasReadIncidentFilesScope && getIncidentVideoStatus();
        if (selectedIncident) {
            return () => {
                incidentAbortControllerRef.current?.abort();
                incidentVideoDetailsAbortControllerRef.current?.abort();
            };
        }
    }, [selectedIncident]);

    useEffect(() => {
        if (selectedIncident && incidentObj?.objectId) {
            getSnapshot();
            return () => {
                snapshotAbortControllerRef.current?.abort();
            };
        }
    }, [incidentObj?.objectId]);

    useEffect(() => {
        if (incidentObj?.assignee) {
            setAssigneeObject(incidentObj?.assignee);
        }
    }, [incidentObj?.assignee]);

    async function getSnapshot() {
        try {
            snapshotAbortControllerRef.current = new AbortController();
            const { signal } = snapshotAbortControllerRef.current;
            setLoading(true);
            const response = await executeGetSnapshotRequest(
                {
                    incidentId: selectedIncident,
                },
                { signal, disableNotification: true },
            );
            if (response !== undefined) {
                setSrc(response);
            } else {
                setError(true);
            }
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    async function getUsers() {
        try {
            setLoadingAssignee(true);
            const response = await executeGetUsersRequest({ scope: SCOPES.VIEW_INCIDENTS_PAGE });
            if (response) {
                setLoadingAssignee(false);
                setAssigneeList(
                    moveItemToFirst<Assignee>(
                        filterUsers(response),
                        (assignee: Assignee) => assignee.user_id === user?.sub,
                    ),
                );
            }
        } catch (error) {
            setLoadingAssignee(false);
            console.log(error);
        }
    }

    async function getIncidentById() {
        if (selectedIncident) {
            try {
                incidentAbortControllerRef.current = new AbortController();
                const { signal } = incidentAbortControllerRef.current;
                const response = await executeGetIncidentByIdRequest(selectedIncident, { signal });
                if (response) {
                    setIncident(response);
                    setAssigneeObject(response?.assignee);
                    setStatusId(response?.status?.id);
                    setSalesForceId(response?.additionalAttributes?.salesForceId);
                    const rego = response?.additionalAttributes?.regoUpdate;
                    if (rego) {
                        setRegoUpdate(rego);
                        setIsRegoEdited(true);
                    } else {
                        setRegoUpdate(response?.altIdentificationNo);
                        setIsRegoEdited(false);
                    }

                    const location = locations.find((loc: Location) => loc.id === response?.locationId);
                    const group = locations.find((group) => group.id === location?.parentId);
                    const region = locations.find((region) => region.id === group?.parentId);
                    const processedData = {
                        ...response,
                        locationName: location?.name,
                        lgaName: group?.name,
                        regionName: region?.name,
                    };
                    setIncidentsData(processedData);
                    const toStatuses = processedData?.status?.toTransitions.map((s: Status) => {
                        return {
                            id: s.toStatus.id,
                            name: s.toStatus.name,
                        };
                    });
                    if (toStatuses) setStatuses([...toStatuses]);
                    processComments(response.comments);
                }
            } catch (e) {
                setIsIncidentError(true);
                console.log(e);
            }
        }
    }

    async function downloadFileUsingPresignedURL() {
        setLoadingVideoDownload(true);
        const extension = incidentFileData?.fileKey?.split(".").pop();
        const fileName = `${incidentData?.idInc}-${incidentData?.incident}.${extension}`;
        if (incidentFileData && incidentFileData.fileKey) {
            await saveFile(() => getIncidentVideoPresignedURL(incidentFileData?.fileId, fileName), fileName);
        }
        setLoadingVideoDownload(false);
    }

    async function playIncidentVideo() {
        setLoadingVideoPlay(true);
        if(incidentFileData?.fileId) await getIncidentVideoPresignedURL(incidentFileData?.fileId);
        setLoadingVideoPlay(false);
    }

    async function getIncidentVideoStatus() {
        if(!selectedIncident) return;
        setIsFetchingFileStatus(true);
        try {
            incidentVideoDetailsAbortControllerRef.current = new AbortController();
            const { signal } = incidentVideoDetailsAbortControllerRef.current;
            const response = await executeGetIncidentVideoStatusRequest(
            {
                referenceDomain: "Incident",
                referenceId: selectedIncident,
            },
            { signal },
            );
            if(response?.length) {
                const [{ status }] = response;
                setIncidentFileData(response[0]);
                setIncidentFileStatus(status);
                return;
            }
            setIncidentFileStatus(IncidentFileStatus.NOT_REQUESTED);
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsFetchingFileStatus(false);
        }
    }

    async function getIncidentVideoPresignedURL(fileId: string, fileName?: string) {
        try {
            videoPresignedURLAbortControllerRef.current = new AbortController();
            const { signal } = videoPresignedURLAbortControllerRef.current;
            const response = await executeObtainFileDownloadPresignedURL(
            fileId,
            fileName ? {contentDisposition: `attachment; filename=${fileName}`} : {},
            { signal },
            );
            const { presignedUrl } = response;
            setFilePresignedURL(presignedUrl);
            return presignedUrl as string;
        } catch (error) {
            console.error(error);
        }
    }

    async function requestIncidentVideo() {
        try {
            setVideoRequestLoading(true);
            if(!incidentObj) return;
            const response = await executeQueueIncidentVideoRequest(selectedIncident);
            if(response.message) {
                setIncidentFileStatus(IncidentFileStatus.PENDING);
                onPropertyChange &&
                    onPropertyChange(IncidentModalChangeablePropertyType.REQUEST_VIDEO, {
                        incidentId: selectedIncident,
                    });
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setVideoRequestLoading(false);
        }
    }

    async function getCommentsByIncidentId() {
        if (selectedIncident) {
            try {
                const response = await executeGetCommentsByIncidentIdRequest(selectedIncident);
                if (response) {
                    processComments(response);
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }

    function isIncidentRetentionExpired(incidentDate: string): boolean {
        const expirationDate = new Date(incidentDate);
        expirationDate.setDate(expirationDate.getDate() + RETENTION_PERIOD_IN_DAYS);
        return new Date(expirationDate) < new Date();
    }

    function processComments(comments: Comment[]) {
        const commentsObj = comments.map((com: Comment) => {
            return {
                comment: com?.comment,
                user: com?.createdBy?.userName,
                date: com?.createdAt,
                picture:
                    com?.createdBy?.picture || assigneeList.find((u) => u.user_id === com?.createdBy?.userId)?.picture,
            };
        });
        const sortedComments = commentsObj.sort(
            (x: CommentObj, y: CommentObj) => +new Date(y.date) - +new Date(x.date),
        );
        setComments(sortedComments);
    }

    async function save(v: SelectChangeEvent<string>) {
        const statusId = v.target.value;
        setStatusId(statusId);
        setLoadingStatus(true);
        try {
            const response = await executeUpdateIncidentStatusRequest(selectedIncident, { statusId });
            if (response) {
                onPropertyChange && onPropertyChange(IncidentModalChangeablePropertyType.STATUS);
                const updatedIncident = incidentObj;
                const incidentStatus = response.status as IncidentStatus;
                if (updatedIncident) {
                    updatedIncident.status = incidentStatus;
                    setIncident(updatedIncident);

                    const updatedIncidentData = incidentData;
                    if (updatedIncidentData) {
                        updatedIncidentData.status = response.status;
                        setIncidentsData(updatedIncidentData);

                        const toStatuses = response?.status?.toTransitions.map((s: Status) => {
                            return {
                                id: s.toStatus.id,
                                name: s.toStatus.name,
                            };
                        });
                        setStatuses([...toStatuses]);
                        setStatusId(response?.status?.id);
                    }
                }
            }
        } catch (e) {
            setStatusId("");
            setNotifier()({
                type: "error",
                message: "Status update failed",
                isAutoClose: true,
            });
            console.log(e);
        }
        setLoadingStatus(false);
    }

    function onClickDialogCloseButton() {
        onCloseDialog();
        onClose();
    }
    function onCloseDialog() {
        setOpen(false);
        setSrc(undefined);
        setIncidentsData(null);
        setError(false);
        setRegoEditable(false);
        setSalesForceIdEditable(false);
        setIncident(null);
        setAssigneeList([]);
        setAssigneeObject(null);
        setIsIncidentError(false);
        setIncidentFileData(undefined);
        setIncidentFileStatus(IncidentFileStatus.LOADING);
        setIsFetchingFileStatus(false);
        setVideoLinkLoading(false);
        setVideoRequestLoading(false);
        setLoadingVideoDownload(false);
        setLoadingVideoPlay(false);
        setFilePresignedURL("");
    }

    async function saveComment(comment: string) {
        try {
            const response = await executeCreateIncidentCommentRequest(selectedIncident, { comment });
            if (response) {
                getCommentsByIncidentId();
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function assignUserToIncident(assignee: Assignee | null, deassignedUser?: Assignee | null) {
        try {
            setLoadingAssignee(true);
            const response = await executeAssignUserToIncidentRequest({
                assignee: assignee || null,
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                incidentId: incidentObj?.id!,
            });
            if (response) {
                const queryParams = Object.fromEntries([...searchParams]);
                if (assignee) {
                    setUrlParams({
                        ...queryParams,
                        selectedAssignee: assignee?.email,
                    });
                }

                if (deassignedUser) {
                    setUrlParams({
                        ...queryParams,
                        deselectedAssignee: deassignedUser?.email,
                    });
                }

                setLoadingAssignee(false);
                onPropertyChange && onPropertyChange(IncidentModalChangeablePropertyType.ASSIGNEE);
            }
        } catch (error) {
            setNotifier()({
                type: "error",
                message: "User assignment failed",
                isAutoClose: true,
            });
            console.log(error);
        }
    }

    async function assignCurrentUserToIncident() {
        if (!isUserAssignedAlready()) {
            const currentUser = assigneeList.find((assignee) => assignee.user_id === user?.sub);
            await assignUserToIncident(currentUser!);
            setAssigneeObject(currentUser);
            getIncidentById();
        }
    }

    function isUserAssignedAlready() {
        return assigneeObject?.user_id === user?.sub;
    }

    // this is temporary fix for checking user available for assigning related to VZ-2287 and VZ-2290
    function isCurrentUserAvailableInList() {
        const filteredUser = assigneeList.find((assignee) => assignee.user_id === user?.sub);
        return !!filteredUser;
    }

    async function getIncidentFootage() {
        setVideoLinkLoading(true);
        try {
            const url = await executeGetIncidentVideoFootageRequest(selectedIncident);
            if (url) {
                window.open(url, "_blank", "noopener,noreferrer");
            }
        } catch (err) {
            // TODO: Display error
            console.error(err);
        } finally {
            setVideoLinkLoading(false);
        }
    }

    function onRegoUpdateChange(rego: string) {
        setRegoUpdate(rego);
    }

    async function onRegoUpdateSave() {
        setLoadingRego(true);
        const incident: Partial<Incident> = {
            id: incidentObj?.id,
            additionalAttributes: {
                regoUpdate,
            },
        };
        try {
            const response = await executeUpdateIncidentRegoRequest(selectedIncident, incident);
            if (response) {
                if (response.additionalAttributes.regoUpdate !== regoUpdate) {
                    throw error;
                }
                setIsRegoEdited(true);
                onPropertyChange && onPropertyChange(IncidentModalChangeablePropertyType.REGO);
            }
        } catch (e) {
            setNotifier()({
                type: "error",
                message: "Rego update failed",
                isAutoClose: true,
            });
            setRegoUpdate("");
            console.log(e);
        }
        setRegoEditable(false);
        setLoadingRego(false);
    }

    function onClickRegoEdit() {
        setRegoEditable(true);
    }

    function onRegoUpdateClear() {
        setRegoEditable(false);
        if (incidentObj?.additionalAttributes?.regoUpdate) {
            setRegoUpdate(incidentObj.additionalAttributes.regoUpdate);
        } else {
            setRegoUpdate(incidentObj?.altIdentificationNo);
        }
    }

    function onSalessForceIdChange(salesForceId: string) {
        setSalesForceId(salesForceId);
        setSalesForceIdEditable(true);
    }

    async function onSalesForceIdSave() {
        setLoadingSalesForceId(true);
        const incident: Partial<Incident> = {
            id: incidentObj?.id,
            additionalAttributes: {
                salesForceId,
            },
        };
        try {
            const response = await executeUpdateIncidentRegoRequest(selectedIncident, incident);
            if (response) {
                if (response.additionalAttributes.salesForceId !== salesForceId) {
                    throw error;
                }
                onPropertyChange && onPropertyChange(IncidentModalChangeablePropertyType.SALES_FORCE_ID);
            }
        } catch (e) {
            setNotifier()({
                type: "error",
                message: "Salesforce ID update failed",
                isAutoClose: true,
            });
            setSalesForceId(undefined);
            console.log(e);
        }
        setSalesForceIdEditable(false);
        setLoadingSalesForceId(false);
    }

    function onClickEditSalesForceId() {
        setSalesForceIdEditable(true);
    }

    function onSalesForceIdClear() {
        setSalesForceIdEditable(false);
        setSalesForceId(incidentData?.additionalAttributes?.salesForceId);
    }

    async function handlePlayer(status: boolean) {
        if (status) {
            await findAndAttachDevice(incidentObj?.locationId || "");
            setIsPlayerOpen(status);
            return;
        }
        setFilePresignedURL("");
        setIsPlayerOpen(status);
    }

    async function findAndAttachDevice(locationId: string) {
        const deviceList = await getIncidentGpuDevices(locationId);
        const matchedDevice = deviceList?.find(device => device.serialId === incidentObj?.meta?.cameraId);
        setIncidentGpuDevice(matchedDevice || null);
    }

    async function getIncidentGpuDevices(locationId: string) {
        try {
            videoGpuDeviceAbortControllerRef.current = new AbortController();
            const { signal } = videoGpuDeviceAbortControllerRef.current;
            const response = await executeGetAllDevicesRequest(
                { locationId },
                { signal },
            );
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Dialog
                onClose={onClickDialogCloseButton}
                open={open}
                fullScreen={isLg}
                maxWidth={false}
                sx={{
                    maxWidth: "1366px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    margin: "auto",
                    width: "658px",
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onClickDialogCloseButton}
                    sx={(theme) => ({
                        position: "absolute",
                        width: "38px",
                        height: "38px",
                        right: "8px",
                        [theme.breakpoints.up("md")]: {
                            top: "10px",
                        },
                        [theme.breakpoints.only("md")]: {
                            top: "5px",
                        },
                    })}
                >
                    <GrClose fontSize={40} fontWeight={600} />
                </IconButton>
                <DialogContent sx={{ minWidth: !isSmallScreen ? "1366px" : "658px" }}>
                    {incidentData && !isIncidentError && (
                        <>
                            <IncidentModalHeader
                                incidentId={incidentData?.idInc}
                                location={incidentData?.locationName}
                                lga={incidentData?.lgaName}
                                videoLinkLoading={videoLinkLoading}
                                requestVideoLoading={videoRequestLoading}
                                incidentFileStatus={incidentFileStatus}
                                playVideoLoading={loadingVideoPlay || !incidentFileData?.fileId}
                                downloadVideoLoading={loadingVideoDownload || !incidentFileData?.fileId}
                                fileStatusFetching={isFetchingFileStatus}
                                locationId={incidentData?.locationId}
                                onViewFootageClick={getIncidentFootage}
                                onRequestVideo={requestIncidentVideo}
                                onPlayVideo={playIncidentVideo}
                                onDownloadVideo={downloadFileUsingPresignedURL}
                                isRetentionPeriodExpired={isIncidentRetentionExpired(incidentObj?.dateTime)}
                                handlePlayer={handlePlayer}
                            />
                            <Grid
                                container
                                spacing={2}
                                marginTop={1}
                                paddingRight={2}
                                sx={{ paddingRight: { md: "0px", lg: "16px" } }}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <LoadingWrapper isLoading={loading} style={{ minHeight: "300px" }}>
                                        {src && !error ? (
                                            <Link
                                                href={`/image/snapshot?incidentId=${selectedIncident}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <IncidentImage
                                                    incidentImageUrl={src}
                                                    metaData={incidentObj?.metaData}
                                                />
                                            </Link>
                                        ) : (
                                            <NoImage />
                                        )}
                                    </LoadingWrapper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        spacing={1}
                                        paddingLeft={{ lg: 4 }}
                                    >
                                        <Grid item xs={12} sm={12} md={12} lg={12} paddingBottom="30px">
                                            {incidentData?.status && (
                                                <IncidentModalStatusSelect
                                                    sx={{
                                                        backgroundColor: "#F3EDFB",
                                                        color: "#6B439D",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        height: "36px",
                                                        borderRadius: "20px",
                                                        padding: "20px",
                                                        // eslint-disable-next-line max-len
                                                        "& .css-1uwzc1h-MuiSelect-select-MuiInputBase-input.Mui-disabled":
                                                            {
                                                                "-webkit-text-fill-color": "#6B439D",
                                                            },
                                                    }}
                                                    value={statusId}
                                                    onChange={save}
                                                    disabled={authorizedStatuses?.length === 0 || loadingStatus}
                                                    loading={loadingStatus}
                                                >
                                                    {authorizedStatuses?.map((status: statusOption) => {
                                                        return (
                                                            <MenuItem
                                                                key={status?.id}
                                                                value={status?.id}
                                                                disabled={status?.disabled}
                                                            >
                                                                {status?.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                    <MenuItem
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        key={incidentData?.status?.id}
                                                        value={incidentData?.status?.id}
                                                    >
                                                        {incidentData?.status?.name}
                                                    </MenuItem>
                                                </IncidentModalStatusSelect>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            marginBottom={1}
                                        >
                                            <Typography fontWeight="600" fontSize="14px" color="#21315B">
                                                Assignee
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={7} lg={8} marginBottom={1}>
                                            <IotVisionAutoComplete
                                                getOptionLabel={(option) => option.name}
                                                PopperComponent={(params) => <StyledAutocompletePopper {...params} />}
                                                options={assigneeList}
                                                onOpen={() => {
                                                    if (assigneeList.length === 0) {
                                                        getUsers();
                                                    }
                                                }}
                                                sx={{ position: "relative" }}
                                                loading={loadingAssignee}
                                                value={assigneeObject}
                                                renderInput={(params) => (
                                                    <IotVisionTextFiled
                                                        {...params}
                                                        InputProps={{
                                                            ...params?.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Avatar
                                                                        sx={{
                                                                            width: 24,
                                                                            height: 24,
                                                                        }}
                                                                        alt="profile"
                                                                        src={assigneeObject?.picture}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <>
                                                                    {loadingAssignee ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        sx={{
                                                            "& > img": {
                                                                mr: 2,
                                                                flexShrink: 0,
                                                            },
                                                        }}
                                                        {...props}
                                                    >
                                                        <Avatar alt="profile" src={option.picture} />
                                                        <div
                                                            style={{
                                                                marginLeft: "8px",
                                                            }}
                                                        >
                                                            {option.name}
                                                            <br />
                                                            <span
                                                                style={{
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                {option.email}
                                                            </span>
                                                        </div>
                                                    </Box>
                                                )}
                                                onChange={(events, value, reason) => {
                                                    if (reason === "clear") {
                                                        setAssigneeObject(null);
                                                        assignUserToIncident(null);
                                                    } else {
                                                        setAssigneeObject(value);
                                                        assignUserToIncident(value);
                                                    }
                                                    setTimeout(() => {
                                                        (document.activeElement as HTMLElement).blur();
                                                    }, 0);
                                                }}
                                            />
                                            <AssignButtonWrapper
                                                item
                                                xs={6}
                                                sm={6}
                                                md={5}
                                                lg={4}
                                                hidden={isUserAssignedAlready()}
                                            >
                                                <CustomizedAssignButton
                                                    onClick={assignCurrentUserToIncident}
                                                    disabled={!isCurrentUserAvailableInList()}
                                                    disableElevation
                                                    disableFocusRipple
                                                    disableRipple
                                                    disableTouchRipple
                                                >
                                                    Assign to me
                                                </CustomizedAssignButton>
                                            </AssignButtonWrapper>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4}
                                            marginTop={!isUserAssignedAlready() ? 2.5 : 0}
                                            paddingBottom="15px"
                                        >
                                            <Typography fontWeight="600" fontSize="14px" color="#21315B">
                                                Object Id
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={7}
                                            lg={8}
                                            marginTop={!isUserAssignedAlready() ? 2.5 : 0}
                                            paddingBottom="15px"
                                        >
                                            <Typography fontSize="14px" color="#21315B">
                                                {incidentData?.objectId}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="600" fontSize="14px" color="#21315B">
                                                Date and Time
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={7}
                                            lg={8}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            paddingBottom="15px"
                                        >
                                            {incidentData?.dateTime && (
                                                <Typography fontSize="14px" color="#21315B">
                                                    <DateView
                                                        dateFormat="Ppp"
                                                        options={{
                                                            locale: enAU,
                                                        }}
                                                    >
                                                        {incidentData?.dateTime}
                                                    </DateView>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="600" fontSize="14px" color="#21315B">
                                                Incident Type
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={7}
                                            lg={8}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontSize="14px" color="#21315B">
                                                {incidentData?.incident}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="600" fontSize="14px" color="#21315B">
                                                Location
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={7}
                                            lg={8}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontSize="14px" color="#21315B">
                                                {incidentData?.locationName}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="600" fontSize="14px" color="#21315B">
                                                LGA
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={7}
                                            lg={8}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontSize="14px" color="#21315B">
                                                {incidentData?.lgaName}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="600" fontSize="14px" color="#21315B">
                                                Region
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={7}
                                            lg={8}
                                            paddingBottom="15px"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontSize="14px" color="#21315B">
                                                {incidentData?.regionName}
                                            </Typography>
                                        </Grid>
                                        <IncidentModalTextField
                                            onClose={onRegoUpdateClear}
                                            onSave={onRegoUpdateSave}
                                            value={regoUpdate}
                                            isEdited={isRegoEdited}
                                            isEditable={regoEditable}
                                            onClickEdit={onClickRegoEdit}
                                            onChange={onRegoUpdateChange}
                                            label="Rego"
                                            loading={loadingRego}
                                            isSmallScreen={isSmallScreen}
                                        />
                                        <IncidentModalTextField
                                            onClose={onSalesForceIdClear}
                                            onSave={onSalesForceIdSave}
                                            value={salesForceId}
                                            isEdited={false}
                                            isEditable={salesForceIdEditable}
                                            onClickEdit={onClickEditSalesForceId}
                                            onChange={onSalessForceIdChange}
                                            label="Sales Force Id"
                                            loading={loadingSalesForceId}
                                            isSmallScreen={isSmallScreen}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider sx={{ paddingTop: "10px" }} />
                            <Grid item xs={12} sm={12} md={12} lg={12} marginTop={2}>
                                <Comments onSave={saveComment} comments={comments} />
                            </Grid>
                        </>
                    )}

                    {!incidentData && !isIncidentError && (
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "500px" }}>
                            <CircularProgress color="primary" />
                        </Box>
                    )}
                    {isIncidentError && (
                        <NotFound
                            primaryErrorMessage="Incident not found"
                            // eslint-disable-next-line max-len
                            secondaryErrorMessage="The incident you are looking for doesn’t exist or other error occurred."
                        />
                    )}
                </DialogContent>
            </Dialog>
            {
                isPlayerOpen && filePresignedURL && (
                    <VideoPlayer
                        filePresignedURL={filePresignedURL!}
                        isOpen={isPlayerOpen}
                        onClose={() => handlePlayer(false)}
                        options={{
                            isActiveDownload: true,
                            fileNameOptions: {
                                useCapturedTime: false,
                                gpuId: incidentGpuDevice?.name,
                                incidentId: incidentObj?.idInc,
                            },
                        }}
                    />
                )
            }
        </>
    );
};

export default IncidentModal;
