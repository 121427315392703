import { Grid } from "@mui/material";
import { ILocationOpenHours, ILocationOpenHoursRange } from "reducers/newLocation.reducer";
import { formatHours } from "utils/time-format";
import { AiFillClockCircle } from "react-icons/ai";
import { stringCapitalizer, stringMapper } from "utils/common";
import React from "react";

interface OperatingHoursProps {
    operatingHours: ILocationOpenHours | undefined;
}

const dateMapping = [
    { from: "WEEK_DAY", to: "WEEKDAY" },
    { from: "WEEK_DAYS", to: "WEEKDAYS" },
];

const paddingTopGridStyle = {
    xs: "0 !important",
    md: "16px !important",
    lg: "16px !important",
};

export const OperatingHours: React.FC<OperatingHoursProps> = ({ operatingHours }) => {
    const keys = Object.keys(operatingHours ?? {});
    const opHours = keys.map((day) => {
        const opData = operatingHours ? operatingHours[day as keyof ILocationOpenHours] : [];
        let intervals: string | string[] = "";
        if (opData) {
            const dataArray = [...opData];
            dataArray.sort((a: ILocationOpenHoursRange, b: ILocationOpenHoursRange) => a.from - b.from);
            intervals = dataArray.map((time) => `${formatHours(time.from)} - ${formatHours(time.to)}`);
            intervals = intervals.join(", ");
        }
        return opData && opData.length ? (
            <Grid container spacing={2} sx={{ marginLeft: "1%" }}>
                <Grid xs={12} md={4} lg={3} fontStyle="italic" item>
                    {stringCapitalizer(stringMapper(day, dateMapping))}
                </Grid>
                <Grid xs={12} md={8} lg={9} item fontWeight="100" paddingTop={paddingTopGridStyle}>
                    {intervals}
                </Grid>
            </Grid>
        ) : null;
    });
    if (opHours.every((itm) => itm === null)) {
        return null;
    }
    return keys.length ? (
        <Grid item xs={12} className="region-statlocation-row-right">
            <Grid item xs={12} className="region-statlocation-row-subrow" sx={{ flexDirection: "column" }}>
                <Grid item display="flex" alignItems="center">
                    <AiFillClockCircle />
                    Operating Hours:
                </Grid>
                {opHours}
            </Grid>
        </Grid>
    ) : null;
};
