import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { LocationWrapper, Location } from "reducers/newLocation.reducer";
import { RequestOptions } from "axios-types";
import { LOCATION_TREE_URL } from "constants/locationApi.constants";
import { createQueryParams } from "utils/create-query-params";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export function executeRetrieveLocationsRequest(data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, LocationWrapper>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/location/location/${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeRetrieveFavoritesRequest(options?: any) {
    return ExecuteAsync({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: "/location/favorite/",
        signal: options?.signal,
    });
}

export function newExecuteRetrieveLocationsRequest(options?: RequestOptions) {
    return ExecuteAsync<any, Location>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: LOCATION_TREE_URL,
        signal: options?.signal,
    });
}


export function executeReportRetrievalRequest(data?: Record<string, any>, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/retrieval-web-api/reports${createQueryParams(data)}`,
        signal: options?.signal,
    });
}
