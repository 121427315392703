import { Grid } from "@mui/material";
import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

interface RegionOptions {
    name: string;
    value: number | boolean | string;
    icon?: React.ReactElement;
}

interface RegionDetailCardProps {
    region: string;
    headerIcon?: React.ReactElement;
    options: RegionOptions[];
}

const RegionDetailCard: React.FC<RegionDetailCardProps> = ({ region, options, headerIcon }) => {
    return (
        <Grid container className="region-stat-card-wrapper m-0">
            <Grid container item xs={11} sm={11} md={10} lg={11} className="region-statlocation-card-Innerwrapper">
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <div className="region-statlocation-row">
                        <div className="LocationIcon-wrapper">{headerIcon ?? <FaMapMarkerAlt />}</div>
                        <span className="region-statlocation-header">{region}</span>
                    </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} lg={6} className="region-stat-innaer-wrapper_right">
                    <Grid item xs={12} sm={12} md={12} lg={12} className="mobilemargin">
                        <Grid item xs={12} sm={12} md={12} lg={12} className="region-state-row-left">
                            <div>
                                {options.map((opt: RegionOptions) => (
                                    <div>
                                        {opt.icon}
                                        {opt.name}: &nbsp;
                                        <span className="fw400">{opt.value}</span>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid
                item
                xs={1}
                sm={1}
                md={2}
                lg={1}
                className="right-content right-content-data-red"
            >
                 <Grid item xs={1} sm={1} md={2} lg={1} className="right-content right-content-data-yellow">
                <div className="txtWrapper">
                    <div className="lblOne">Traffic</div>
                    <div className="lblTwo">High</div>
                </div>
            </Grid> */}
        </Grid>
    );
};

export default RegionDetailCard;
