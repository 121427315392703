/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, AutocompleteChangeReason, AutocompleteRenderInputParams, Grid, Theme } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { getIncidentFilterDropDownValues } from "api/app-air-quality/air-incidents.api";
import CommonDateRangePicker, { DatePickerValue, pickerRanges } from "components/common/CommonDateRangePicker";
import { PrimaryButton, SecondaryButton, StyledTextField } from "features/app-air-quality/common";
import { pagePath } from "pages/app-oversized-vehicle";

import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { AlertsField, IdLabel } from "../../../../types/incident/alert-filter";

interface filterTypes {
    [k: string]: IdLabel[] | DatePickerValue;
    status: IdLabel[];
    room: IdLabel[];
    type: IdLabel[];
    alert: IdLabel[];
    time: DatePickerValue;
}

interface FilterOption {
    name: string;
    value: any[];
}

const useStyles = makeStyles({
    listbox: {
        fontSize: "12px",
        fontWeight: 400,
    },
    input: {
        "&.MuiAutocomplete-input": {
            marginTop: "-12px",
        },
    },
});

const initialFilters: filterTypes = {
    status: [],
    room: [],
    type: [],
    alert: [],
    time: {
        fromTime: "",
        toTime: "",
        range: pickerRanges.DateRange,
    },
};

const initialPickerValue: DatePickerValue = {
    fromTime: "",
    toTime: "",
    range: pickerRanges.DateRange,
};

const IncidentsFilter: React.FC = (): JSX.Element => {
    const theme = useTheme<Theme>();
    const { pathname } = useLocation();
    const [isAlertPage, setIsAlertPage] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState(initialFilters);
    const { locationsTree } = useSelector((state) => state.newLocation);
    const [pickerValue, setPickerValue] = useState<DatePickerValue>(initialPickerValue);
    const [searchParams, setSearchParams] = useSearchParams();
    const { idInc, locationId, status, fromTime, toTime, range } = Object.fromEntries([...searchParams]);

    const initialFormData = {
        alert: {
            id: "",
            label: "",
        },
        type: {
            id: "",
            label: "",
        },
        time: pickerValue,
        status: {
            id: "",
            label: "",
        },
        room: {
            id: "",
            label: "",
        },
        idInc: "",
    };
    const [roomFilter, setRoomFilter] = useState<IdLabel[]>([]);
    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { isValid },
    } = useForm<AlertsField>({
        defaultValues: initialFormData,
    });

    useEffect(() => {
        const rooms = [...new Set(locationsTree.filter((room) => room.type === "location"))];
        setRoomFilter(rooms.map((room) => ({ id: room.id, label: room.name })));
    }, [locationsTree]);

    useEffect(() => {
        setIsAlertPage(pathname.includes(pagePath.Detection));
    }, [pathname]);

    useEffect(() => {
        fetchFilterTypes();
    }, []);

    useEffect(() => {
        reset({
            room: locationId
                ? {
                      label: findLabel(roomFilter, locationId),
                      id: locationId,
                  }
                : { label: "", id: "" },
            status: status ? { label: findLabel(filterOptions.status, status), id: status } : { label: "", id: "" },
            time: {
                fromTime: fromTime || "",
                toTime: toTime || "",
                range: range || pickerRanges.DateRange,
            },
            idInc: idInc || "",
        });
        setPickerValue({
            fromTime: fromTime || "",
            toTime: toTime || "",
            range: range || pickerRanges.DateRange,
        });
    }, [searchParams, filterOptions, roomFilter]);

    const findLabel = (options: IdLabel[], id: string): string => {
        if (Array.isArray(options) && options.length !== 0) {
            return options.find((opt) => opt.id === id)?.label ?? "";
        }
        return "";
    };

    const autoCompleteClasses = useStyles();

    const onSubmit: SubmitHandler<AlertsField> = (data): void => {
        const submitSearchParams: URLSearchParamsInit | undefined = {
            pageIndex: "1",
            ...(data?.idInc ? { idInc: data?.idInc } : {}),
            ...(data?.room?.id ? { locationId: data?.room?.id } : {}),
            ...(data?.status.id ? { status: data?.status?.id } : {}),
            ...(data?.time?.fromTime ? { fromTime: data?.time?.fromTime } : {}),
            ...(data?.time?.toTime ? { toTime: data?.time?.toTime } : {}),
            ...(data?.time?.range !== pickerRanges.DateRange ? { range: data?.time?.range } : {}),
        };
        setSearchParams(submitSearchParams);
    };

    const handleReset = (): void => {
        setPickerValue(initialPickerValue);
        reset(initialFormData);
        setSearchParams({
            pageIndex: "1",
        });
    };

    const fetchFilterTypes = async () => {
        const response: any[] = await getIncidentFilterDropDownValues();
        const data = initialFilters;
        if (response) {
            response.forEach((fil: FilterOption) => {
                switch (fil.name) {
                    case "Incident":
                        data.alert = fil.value.map((itm) => ({
                            id: itm,
                        }));
                        break;
                    case "Status":
                        data.status = fil.value;
                        break;
                    default:
                        break;
                }
            });
            setFilterOptions({ ...filterOptions, ...data });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} columnSpacing="8px">
                {isAlertPage && (
                    <Grid item xs={12} md={6} lg={3} xl="auto">
                        <Controller
                            name="room"
                            control={control}
                            render={({ field: { value } }) => (
                                <Autocomplete
                                    classes={{
                                        input: autoCompleteClasses.input,
                                        listbox: autoCompleteClasses.listbox,
                                    }}
                                    disablePortal
                                    options={roomFilter}
                                    value={value}
                                    onChange={(
                                        event: React.SyntheticEvent,
                                        value: any,
                                        reason: AutocompleteChangeReason,
                                    ) => {
                                        if (reason === "clear") {
                                            setValue("room", {
                                                id: "",
                                                label: "",
                                            });
                                        } else {
                                            setValue("room", value);
                                        }
                                    }}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <StyledTextField
                                            sx={{
                                                [theme?.breakpoints.up("xl")]: {
                                                    width: "250px",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    width: "100%",
                                                    height: "25px",
                                                },
                                            }}
                                            {...params}
                                            placeholder="Location"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6} lg={3} xl="auto">
                    <Controller
                        name="time"
                        control={control}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { value } }) => (
                            <CommonDateRangePicker
                                value={pickerValue}
                                onDatePickerApply={(startTime, endTime, range) => {
                                    setPickerValue({
                                        fromTime: startTime,
                                        toTime: endTime,
                                        range,
                                    });
                                    setValue("time.fromTime", startTime);
                                    setValue("time.toTime", endTime);
                                    setValue("time.range", range);
                                }}
                                onDatePickerClear={() => {
                                    setValue("time.fromTime", "");
                                    setValue("time.toTime", "");
                                    setValue("time.range", pickerRanges.DateRange);
                                    setPickerValue({
                                        fromTime: "",
                                        toTime: "",
                                        range: pickerRanges.DateRange,
                                    });
                                }}
                                sx={{
                                    [theme?.breakpoints.up("xl")]: {
                                        "& .MuiInputBase-root": {
                                            height: "25px",
                                            width: "250px",
                                        },
                                    },
                                    "& .MuiInputBase-root": {
                                        height: "25px",
                                    },

                                    "& input": {
                                        fontSize: "12px",
                                        color: "#A3A3A3",
                                    },
                                }}
                                buttonText="Clear"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} xl="auto">
                    <Controller
                        name="idInc"
                        control={control}
                        rules={{
                            required: false,
                            maxLength: {
                                value: 9,
                                message: "9 digits can be allowed",
                            },
                            pattern: {
                                value: /^\d+$/,
                                message: "Should be integer",
                            },
                        }}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({ field: { value }, fieldState: { invalid, error } }) => (
                            <StyledTextField
                                sx={{
                                    [theme?.breakpoints.up("xl")]: {
                                        width: "250px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        width: "100%",
                                        height: "25px",
                                    },
                                }}
                                value={value}
                                placeholder="Detection ID"
                                defaultValue={idInc}
                                onChange={(event) => {
                                    setValue("idInc", event?.target?.value, { shouldDirty: true });
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} xl="auto">
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <PrimaryButton
                                sx={{
                                    width: "100%",
                                    [theme?.breakpoints.up("xl")]: {
                                        width: isAlertPage ? "110px" : "120px",
                                    },
                                }}
                                disabled={!isValid}
                                type="submit"
                            >
                                Apply
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <SecondaryButton
                                sx={{
                                    width: "100%",
                                    [theme?.breakpoints.up("xl")]: {
                                        width: isAlertPage ? "110px" : "120px",
                                    },
                                }}
                                onClick={handleReset}
                            >
                                Reset
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default IncidentsFilter;
