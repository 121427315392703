import { useEffect, useState } from "react";
import { format } from "date-fns";
import { enAU } from "date-fns/locale";

export interface DateViewProps {
    children: string;
    dateFormat?: string;
    options?: {
        locale?: Locale;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        firstWeekContainsDate?: number;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
    };
}
const DateView = (props: DateViewProps) => {
    const [formatted, setFormatted] = useState("");
    const { children, dateFormat, options } = props;

    useEffect(() => {
        if (typeof children === "string") {
            const date = new Date(children);
            if (dateFormat && options) {
                setFormatted(format(date, dateFormat, options));
            } else if (dateFormat) {
                setFormatted(format(date, dateFormat));
            } else {
                setFormatted(format(date, "Pp", { locale: enAU }));
            }
        } else {
            setFormatted("Invalid Date");
        }
    }, [dateFormat, children, options]);

    return <>{formatted}</>;
};

export default DateView;
