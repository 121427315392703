import React from "react";
import { Bar } from "react-chartjs-2";

interface LineChartProps {
    data: number[];
    labels: string[];
    backgroundColor: string;
    useGrid?: boolean;
    useTicks?: boolean;
    height?: string;
}

const BarChart: React.FC<LineChartProps> = ({
    data,
    labels,
    backgroundColor,
    height = "55px",
    useGrid = false,
    useTicks = false,
}) => {
    return (
        <div
            style={{
                height,
                paddingLeft: "15px",
                paddingRight: "15px",
            }}
        >
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            data,
                            backgroundColor,
                        },
                    ],
                }}
                options={{
                    scales: {
                        x: {
                            grid: {
                                display: useGrid,
                            },
                            ticks: {
                                display: useTicks,
                            },
                        },
                        y: {
                            grid: {
                                display: useGrid,
                            },
                            ticks: {
                                display: useTicks,
                            },
                        },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                }}
            />
        </div>
    );
};

export default BarChart;
