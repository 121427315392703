/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import {
    AutocompleteChangeReason,
    Avatar,
    Box,
    Grid,
    PopperProps,
    Popper,
    useMediaQuery,
    styled,
    useTheme,
} from "@mui/material";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";
import { getIncidentFilterDropDownValues } from "api/app-taxi-ranking/taxi-incidents.api";
import CommonDateRangePicker, { DatePickerValue, pickerRanges } from "components/common/CommonDateRangePicker";
import IotVisionButton from "components/common/IotVisionButton";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import { URLSearchParamsInit, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { filterUsers, moveItemToFirst } from "utils/data-manipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { Assignee } from "types";
import { IncidentType, PresetFilterLabels, PresetFilterTypeOptions } from "enums/incident.enum";

const PLACEHOLDER_INCIDENT_TYPE = "Incident Type";
const PLACE_HOLDER_PRESET_FILTERS = "Preset Filters";

export const presetFilterOptions: Options = {
    name: PLACE_HOLDER_PRESET_FILTERS,
    value: [
        {
            id: `${PresetFilterTypeOptions.EXCLUDE_REGO_WITH_INCIDENT_TYPE}:T:${IncidentType.CAR_IN_TAXIRANK}`,
            label: PresetFilterLabels.EXCLUDE_T_PLATE_IN_CAR_IN_TAXIRANK,
        },
        {
            id: `${PresetFilterTypeOptions.ONLY_REGO_WITH_INCIDENT_TYPE}:T:${IncidentType.CAR_IN_TAXIRANK}`,
            label: PresetFilterLabels.ONLY_T_PLATE_IN_CAR_IN_TAXIRANK,
        },
    ],
};

interface DropdownValue {
    id: string;
    label: string;
}
interface OptionsLgaOrLocation {
    name: string;
    value: DropdownValue[];
}

const incidentOptions: Options = {
    name: "",
    value: [],
};

const statusOptions: Options = {
    name: "",
    value: [],
};

const assigneeOptions: Options = {
    name: "",
    value: [],
};
interface IncidentsPageFilterPanelProps {
    locationOptions: OptionsLgaOrLocation;
    lgaOptions: OptionsLgaOrLocation;
    isAllLocationEnable?: boolean;
    isRegionEnable?: boolean;
    isLgaEnable?: boolean;
}
interface Options {
    name: string;
    value: any[];
}
interface AutoLabelId {
    label: string;
    id: string;
}

interface AutoLabelAssignee {
    name: string;
    // eslint-disable-next-line camelcase
    user_id: string;
    picture: string;
    email: string;
}
interface IncidentFilterFormField {
    dateTime: DatePickerValue;
    altIdentificationNo?: string;
    groupId?: AutoLabelId;
    status?: AutoLabelId;
    locationId?: AutoLabelId;
    incident?: string;
    idInc?: string;
    assignedUserID?: AutoLabelAssignee;
    preFilter?: AutoLabelId;
}
interface dropDownValue {
    name: string;
    value: string[];
}


const StyledAutocompletePopper = styled((popperProps: PopperProps) => {
    const theme = useTheme();
    const isBelowLgScreen = useMediaQuery(theme.breakpoints.down("lg"));
    return (
        <Popper
            {...popperProps}
            style={{
                width: "fit-content",
            }}
            placement={isBelowLgScreen ? "bottom" : "bottom-start"}
        />
    );
})(({ theme }) => {
    const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
    return {
        "& .MuiAutocomplete-listbox": {
            height: "100%",
            maxHeight: isXlScreen ? "600px" : "200px",
        },
    };
});

const IncidentsPageFilterPanel: React.FC<IncidentsPageFilterPanelProps> = ({
    locationOptions,
    lgaOptions,
    isAllLocationEnable,
    isLgaEnable,
    isRegionEnable,
}) => {
    const [dropDownTypesValues, setDropDownTypes] = React.useState<Options[]>([]);
    const { groupId: groupUrlId } = useParams();
    const { user } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        altIdentificationNo,
        groupId,
        fromTime,
        toTime,
        range,
        status,
        locationId,
        incident,
        assignedUserID,
        idInc,
        selectedAssignee,
        deselectedAssignee,
        preFilter,
    } = Object.fromEntries([...searchParams]) || {};
    const [pickerValue, setPickerValue] = useState<DatePickerValue>({
        fromTime: "",
        toTime: "",
        range: pickerRanges.DateRange,
    });

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        trigger,
        formState: { isDirty, isValid },
    } = useForm<IncidentFilterFormField>({
        defaultValues: {
            altIdentificationNo: "",
            groupId: { label: "", id: "" },
            status: { label: "", id: "" },
            locationId: { label: "", id: "" },
            incident: "",
            idInc: "",
            dateTime: pickerValue,
            assignedUserID: { name: "", user_id: "", email: "", picture: "" },
            preFilter: { label: "", id: "" },
        },
    });
    const filtersAbortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        dropDownTypesValues.forEach((type: dropDownValue) => defineDropDownValues(type));
    }, [dropDownTypesValues]);
    useEffect(() => {
        reset({
            altIdentificationNo: altIdentificationNo || "",
            groupId: groupId ? { label: findLabel(lgaOptions, groupId), id: groupId } : { label: "", id: "" },
            status: status
                ? {
                      label: findLabel(statusOptions, status),
                      id: status,
                  }
                : { label: "", id: "" },
            locationId: locationId
                ? {
                      label: findLabel(locationOptions, locationId),
                      id: locationId,
                  }
                : { label: "", id: "" },
            incident: incident || "",
            idInc: idInc || "",
            dateTime: {
                fromTime: fromTime || "",
                toTime: toTime || "",
                range: range || pickerRanges.DateRange,
            },
            assignedUserID: assignedUserID
                ? {
                      name: findLabel(assigneeOptions, assignedUserID, "name"),
                      user_id: assignedUserID,
                      email: "",
                      picture: "",
                  }
                : { name: "", user_id: "", email: "", picture: "" },
                preFilter: preFilter
                    ? {
                        label: findLabel(presetFilterOptions, preFilter),
                        id: preFilter,
                    }
                    : { label: "", id: "" },
        });
        setPickerValue({
            fromTime: fromTime || "",
            toTime: toTime || "",
            range: range || pickerRanges.DateRange,
        });
    }, [searchParams, dropDownTypesValues]);

    useEffect(() => {
        if (deselectedAssignee) {
            removeQueryParam("deselectedAssignee");
        }
        getDropDownTypesValues();
        return () => {
            filtersAbortControllerRef.current?.abort();
        };
    }, [groupUrlId, groupId, locationId, assignedUserID, deselectedAssignee]);

    useEffect(() => {
        if (selectedAssignee) {
            const availableAssigneeOptions = assigneeOptions.value.map((option) => option.email);

            if (availableAssigneeOptions.includes(selectedAssignee)) {
                removeQueryParam("selectedAssignee");
            } else {
                getDropDownTypesValues();
            }
        }
    }, [selectedAssignee]);

    const removeQueryParam = (param: string) => {
        const queryParams = Object.fromEntries([...searchParams]);
        delete queryParams[param];
        setSearchParams({ ...queryParams });
    };

    const findLabel = (options: Options, id: string, type?: string): string => {
        if (options?.value && Array.isArray(options?.value) && options.value.length !== 0) {
            if (type) {
                return options?.value?.find((s: AutoLabelAssignee) => s.user_id === id)?.name;
            }
    
            return options?.value?.find((s: AutoLabelId) => s.id === id)?.label;
        }
        return "";
    };

    const defineDropDownValues = (type: Options) => {
        switch (type.name) {
            case "Incident":
                incidentOptions.name = PLACEHOLDER_INCIDENT_TYPE;
                incidentOptions.value = type.value;
                break;
            case "Status":
                statusOptions.name = type.name;
                statusOptions.value = type.value;
                break;
            case "Assignee":
                assigneeOptions.name = type.name;
                assigneeOptions.value = moveItemToFirst<Assignee>(
                    filterUsers(type.value),
                    (assignee: Assignee) => assignee.user_id === user?.sub,
                );
                break;
            default:
        }
    };

    const getDropDownTypesValues = async () => {
        try {
            filtersAbortControllerRef.current = new AbortController();
            const { signal } = filtersAbortControllerRef.current;
            const response = await getIncidentFilterDropDownValues({ signal });
            if (response !== null && Array.isArray(response)) {
                removeQueryParam("selectedAssignee");
                setDropDownTypes(response);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleClear = (): void => {
        reset({
            altIdentificationNo: "",
            groupId: { label: "", id: "" },
            status: { label: "", id: "" },
            locationId: { label: "", id: "" },
            incident: "",
            idInc: "",
            dateTime: {
                fromTime: "",
                toTime: "",
                range: pickerRanges.DateRange,
            },
            assignedUserID: { name: "", user_id: "", email: "", picture: "" },
        });
        setPickerValue({
            fromTime: "",
            toTime: "",
            range: pickerRanges.DateRange,
        });
        setSearchParams({
            pageIndex: "1",
        });
    };
    const onSubmit: SubmitHandler<IncidentFilterFormField> = (data) => {
        const searchParamData: URLSearchParamsInit | undefined = {
            pageIndex: "1",
            ...(data?.altIdentificationNo ? { altIdentificationNo: data?.altIdentificationNo } : {}),
            ...(data?.dateTime?.fromTime ? { fromTime: data?.dateTime?.fromTime } : {}),
            ...(data?.dateTime?.toTime ? { toTime: data?.dateTime?.toTime } : {}),
            ...(data?.dateTime?.range && data?.dateTime?.range !== pickerRanges.DateRange
                ? { range: data?.dateTime?.range }
                : {}),
            ...(data?.groupId?.id ? { groupId: data?.groupId?.id } : {}),
            ...(data?.status?.id ? { status: data?.status?.id } : {}),
            ...(data?.incident ? { incident: data?.incident } : {}),
            ...(data?.idInc ? { idInc: data?.idInc } : {}),
            ...(data?.locationId?.id ? { locationId: data?.locationId?.id } : {}),
            ...(data?.assignedUserID?.user_id ? { assignedUserID: data?.assignedUserID?.user_id } : {}),
            ...(data?.preFilter?.id ? { preFilter: data?.preFilter?.id } : {}),
        };
        setSearchParams(searchParamData);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    {/* @ts-ignore */}
                    <Controller
                        name="altIdentificationNo"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionTextFiled
                                fullWidth
                                placeholder="Rego Number"
                                value={value}
                                onChange={(event) => {
                                    setValue("altIdentificationNo", event?.target?.value, { shouldDirty: true });
                                }}
                            />
                        )}
                    />
                </Grid>
                {(isAllLocationEnable || isRegionEnable) && (
                    <>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <Controller
                                name="groupId"
                                control={control}
                                render={({ field: { value } }) => (
                                    <IotVisionAutoComplete
                                        options={lgaOptions.value}
                                        value={value}
                                        onChange={(
                                            event: React.SyntheticEvent,
                                            value: AutoLabelId,
                                            reason: AutocompleteChangeReason,
                                        ) => {
                                            if (reason === "clear") {
                                                setValue(
                                                    "groupId",
                                                    {
                                                        label: "",
                                                        id: "",
                                                    },
                                                    { shouldDirty: true },
                                                );
                                            } else {
                                                setValue(
                                                    "groupId",
                                                    {
                                                        label: value?.label,
                                                        id: value?.id,
                                                    },
                                                    { shouldDirty: true },
                                                );
                                            }
                                        }}
                                        renderInput={(params: any) => (
                                            <IotVisionTextFiled {...params} placeholder={lgaOptions.name} fullWidth />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <Controller
                                name="locationId"
                                control={control}
                                render={({ field: { value } }) => (
                                    <IotVisionAutoComplete
                                        options={locationOptions.value}
                                        value={value}
                                        onChange={(
                                            event: React.SyntheticEvent,
                                            value: AutoLabelId,
                                            reason: AutocompleteChangeReason,
                                        ) => {
                                            if (reason === "clear") {
                                                setValue(
                                                    "locationId",
                                                    {
                                                        label: "",
                                                        id: "",
                                                    },
                                                    { shouldDirty: true },
                                                );
                                            } else {
                                                setValue(
                                                    "locationId",
                                                    {
                                                        label: value?.label,
                                                        id: value?.id,
                                                    },
                                                    { shouldDirty: true },
                                                );
                                            }
                                        }}
                                        renderInput={(params: any) => (
                                            <IotVisionTextFiled
                                                fullWidth
                                                {...params}
                                                placeholder={locationOptions.name}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </>
                )}
                {isLgaEnable && (
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Controller
                            name="locationId"
                            control={control}
                            render={({ field: { value } }) => (
                                <IotVisionAutoComplete
                                    options={locationOptions.value}
                                    value={value}
                                    onChange={(
                                        event: React.SyntheticEvent,
                                        value: AutoLabelId,
                                        reason: AutocompleteChangeReason,
                                    ) => {
                                        if (reason === "clear") {
                                            setValue(
                                                "locationId",
                                                {
                                                    label: "",
                                                    id: "",
                                                },
                                                { shouldDirty: true },
                                            );
                                        } else {
                                            setValue(
                                                "locationId",
                                                {
                                                    label: value?.label,
                                                    id: value?.id,
                                                },
                                                { shouldDirty: true },
                                            );
                                        }
                                    }}
                                    renderInput={(params: any) => (
                                        <IotVisionTextFiled fullWidth {...params} placeholder={locationOptions.name} />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="incident"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={incidentOptions.value}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: string,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("incident", "", {
                                            shouldDirty: true,
                                        });
                                    } else {
                                        setValue("incident", value, {
                                            shouldDirty: true,
                                        });
                                    }
                                }}
                                renderInput={(params: any) => (
                                    <IotVisionTextFiled fullWidth {...params} placeholder={incidentOptions.name} />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={statusOptions.value}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "status",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "status",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: any) => (
                                    <IotVisionTextFiled fullWidth {...params} placeholder={statusOptions?.name} />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="dateTime"
                        control={control}
                        render={() => (
                            <CommonDateRangePicker
                                value={pickerValue}
                                onPickerMenuClose={() => {
                                    setPickerValue({
                                        ...pickerValue,
                                    });
                                }}
                                onDatePickerApply={(startTime, endTime, range) => {
                                    setPickerValue({
                                        fromTime: startTime,
                                        toTime: endTime,
                                        range,
                                    });
                                    setValue("dateTime.fromTime", startTime, {
                                        shouldDirty: true,
                                    });
                                    setValue("dateTime.toTime", endTime);
                                    setValue("dateTime.range", range);
                                }}
                                onDatePickerClear={() => {
                                    setValue("dateTime.fromTime", "", {
                                        shouldDirty: true,
                                    });
                                    setValue("dateTime.toTime", "");
                                    setValue("dateTime.range", pickerRanges.DateRange);
                                    setPickerValue({
                                        fromTime: "",
                                        toTime: "",
                                        range: pickerRanges.DateRange,
                                    });
                                }}
                                buttonText="Clear"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="idInc"
                        control={control}
                        rules={{
                            required: false,
                            maxLength: {
                                value: 9,
                                message: "9 digits can be allowed",
                            },
                            pattern: {
                                value: /^\d+$/,
                                message: "Should be integer",
                            },
                        }}
                        render={({ field: { value }, fieldState: { invalid, error } }) => (
                            <IotVisionTextFiled
                                fullWidth
                                placeholder="Incident ID"
                                error={invalid}
                                value={value}
                                label={error?.message}
                                onChange={(event) => {
                                    setValue("idInc", event?.target?.value, {
                                        shouldDirty: true,
                                    });
                                    // eslint-disable-next-line no-unused-expressions
                                    invalid && trigger("idInc");
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="assignedUserID"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                PopperComponent={(params) => <StyledAutocompletePopper {...params} />}
                                getOptionLabel={(option) => (option.name ? option.name : "")}
                                value={value}
                                options={assigneeOptions.value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: AutoLabelAssignee,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "assignedUserID",
                                            {
                                                name: "",
                                                user_id: "",
                                                email: "",
                                                picture: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue("assignedUserID", value, {
                                            shouldDirty: true,
                                        });
                                    }
                                }}
                                renderInput={(params: any) => (
                                    <IotVisionTextFiled fullWidth {...params} placeholder={assigneeOptions.name} />
                                )}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                            },
                                        }}
                                        {...props}
                                        key={option.user_id}
                                    >
                                        <Avatar alt="profile" src={option.picture} />
                                        <div
                                            style={{
                                                marginLeft: "8px",
                                            }}
                                        >
                                            {option.name}
                                            <br />

                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {option.email}
                                            </span>
                                        </div>
                                    </Box>
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="preFilter"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={presetFilterOptions.value}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "preFilter",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "preFilter",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: any) => (
                                    <IotVisionTextFiled
                                        fullWidth
                                        {...params}
                                        placeholder={presetFilterOptions?.name}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <IotVisionButton
                                type="submit"
                                fullWidth
                                disabled={!isDirty || !isValid}
                                sx={{
                                    height: "37px",
                                    color: "white !important",
                                }}
                            >
                                Search
                            </IotVisionButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <IotVisionButton
                                fullWidth
                                sx={{
                                    height: "37px",
                                    backgroundColor: "white",
                                    color: "#6b439d !important",
                                    "&:hover": {
                                        background: "white",
                                    },
                                }}
                                onClick={handleClear}
                            >
                                Clear
                            </IotVisionButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default IncidentsPageFilterPanel;
