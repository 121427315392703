import React from "react";
import Grid from "@mui/material/Grid";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillClockCircle, AiFillBell } from "react-icons/ai";
import { Typography } from "@mui/material";

interface LocationInfoCardProps {
    name: string;
    location?: string;
    latitude: string;
    longitude: string;
    scheduledRoadWorkFrom?: string;
    scheduledRoadWorkTo?: string;
    scheduleLabel?: string;
    incidentsCount?: number;
    headerIcon?: React.ReactElement;
    countLabel?: string;
}

const LocationInfoCard: React.FC<LocationInfoCardProps> = ({
    name,
    location,
    latitude,
    longitude,
    scheduledRoadWorkFrom,
    scheduledRoadWorkTo,
    incidentsCount,
    scheduleLabel,
    headerIcon,
    countLabel = "Incident Count",
}) => {
    return (
        <Grid container className="region-statlocation-card-wrapper m-0">
            <Grid item xs={12} className="region-statlocation-card-Innerwrapper">
                <Grid container item xs={12}>
                    <div className="region-statlocation-row">
                        <div className="LocationIcon-wrapper">{headerIcon ?? <FaMapMarkerAlt />}</div>
                        <span className="region-statlocation-header">{name}</span>
                    </div>
                </Grid>
                <Grid item xs={12} className="region-statlocation-innaer-wrapper_bottom">
                    <Grid item xs={12} className="region-statlocation-row-left">
                        {location ? (
                            <Grid item xs={12} className="region-statlocation-row-subrow">
                                {location}
                            </Grid>
                        ) : null}
                        <Grid item xs={12} className="region-statlocation-row-subrow lgaData">
                            {latitude}, {longitude}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="region-statlocation-row-right">
                        {scheduledRoadWorkFrom && scheduledRoadWorkTo ? (
                            <Grid item xs={12} flexDirection="column">
                                <Grid
                                    item
                                    xs={12}
                                    className="region-statlocation-row-subrow"
                                    style={{
                                        alignItems: "center",
                                    }}
                                >
                                    <AiFillClockCircle />
                                    <Typography>{scheduleLabel}</Typography>
                                </Grid>
                                <Grid item xs={12} flexDirection="row" style={{ marginLeft: "4px" }}>
                                    From: {scheduledRoadWorkFrom} - To: {scheduledRoadWorkTo}
                                </Grid>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} flexDirection="column" marginLeft="5%">
                            <Grid
                                item
                                xs={12}
                                className="region-statlocation-row-subrow"
                                style={{
                                    alignItems: "center",
                                }}
                            >
                                <AiFillBell />
                                <Typography>{countLabel}</Typography>
                            </Grid>
                            <Grid item xs={12} flexDirection="row" style={{ marginLeft: "4px" }}>
                                {incidentsCount ?? 0}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LocationInfoCard;
