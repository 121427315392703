/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import IotVisionButton from "components/common/IotVisionButton";
import DateView from "components/dates/DateView";
import React, { useState } from "react";
import { CommentObj } from "../dialogs/IncidentModal/IncidentModal";

interface CommentsProps {
    comments: CommentObj[];
    onSave: (comment: string) => void;
    header?: string;
}

const Comments = ({ comments, onSave, header = "Comments" }: CommentsProps) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [commentText, setCommentText] = useState<string>();

    function onTextFocus() {
        setIsFocused(true);
    }

    function onTextChange(e: any) {
        setCommentText(e.target.value);
    }

    function onClear() {
        setCommentText("");
        setIsFocused(false);
    }

    function onCommentSave() {
        // eslint-disable-next-line no-unused-expressions
        commentText && onSave(commentText);
        setIsFocused(false);
        setCommentText("");
    }

    return (
        <>
            <b>{header}:</b>
            <Grid container>
                {isFocused ? (
                    <div style={{ width: "100%" }}>
                        <Box sx={{ display: "flex" }}>
                            <TextField
                                sx={{ flexGrow: 1 }}
                                value={commentText}
                                placeholder="Add a comment..."
                                multiline
                                fullWidth
                                style={{
                                    paddingBottom: "15px",
                                    paddingTop: "10px",
                                }}
                                onChange={onTextChange}
                                onClick={onTextFocus}
                                size="medium"
                                autoFocus
                            />
                            <IotVisionButton
                                onClick={onCommentSave}
                                sx={{ marginTop: 3, marginLeft: 1, marginRight: 1 }}
                            >
                                Save
                            </IotVisionButton>
                            <IotVisionButton
                                sx={{
                                    backgroundColor: "white",
                                    color: "primary.main",
                                    "&:hover": {
                                        background: "white",
                                    },
                                    marginTop: 3,
                                }}
                                onClick={onClear}
                            >
                                Cancel
                            </IotVisionButton>
                        </Box>
                    </div>
                ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            value={commentText}
                            placeholder="Add a comment..."
                            multiline
                            fullWidth
                            style={{
                                paddingBottom: "15px",
                                paddingTop: "10px",
                            }}
                            onChange={onTextChange}
                            onFocus={onTextFocus}
                            size="medium"
                        />
                    </Grid>
                )}
            </Grid>

            {comments instanceof Array && comments.length > 0 && (
                <List sx={{ bgcolor: "background.paper" }}>
                    {comments instanceof Array &&
                        comments.map((com: any) => {
                            return (
                                <ListItem alignItems="flex-start" key={com.date} style={{ paddingBottom: "0" }}>
                                    <ListItemAvatar>
                                        <Avatar alt="profile" src={com.picture} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography fontSize="14px" fontWeight="600" color="#21315B">
                                                {com.user}
                                                <span
                                                    style={{
                                                        paddingLeft: "10px",
                                                        fontSize: "12px",
                                                        color: "#7A7A7A",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    <DateView>{com.date}</DateView>
                                                </span>
                                            </Typography>
                                        }
                                        secondary={<div style={{ whiteSpace: "pre-wrap" }}>{com.comment}</div>}
                                    />
                                </ListItem>
                            );
                        })}
                </List>
            )}
        </>
    );
};

export default Comments;
